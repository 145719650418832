import {
    INITIALIZE_CHAT_HISTORY,
    RECEIVE_CHAT_MESSAGE,
    RECEIVE_SYSTEM_CHAT_MESSAGE,
    TOGGLE_OPEN_CHAT,
    UPDATE_CHAT_MESSAGE_LIKES,
} from './actions';
import { MessengerState } from './state';
import {
    UserChatMessage,
    SystemChatMessage,
    ChatMessage,
} from '../../../constants/interfaces/ChatMessage';
import { UpdateMessageLikeParams } from './services';

const initialState: MessengerState = {
    open: false,
    messages: [],
};
const messengerReducer = (
    state = initialState,
    action: {
        type: string;
        message: UserChatMessage;
        systemMessage: SystemChatMessage;
        messageLike: UpdateMessageLikeParams;
        chatHistory: UserChatMessage[] | SystemChatMessage[];
        open: boolean;
    }
) => {
    switch (action.type) {
        case TOGGLE_OPEN_CHAT: {
            return {
                ...state,
                open: action.open,
            };
        }
        case RECEIVE_CHAT_MESSAGE: {
            const newMessages = [...state.messages];
            newMessages.unshift({
                MessageId: action.message.MessageId,
                Player: action.message.Player,
                Text: action.message.Text,
                LikeCount: action.message.LikeCount,
                Liked: action.message.Liked,
                system: false,
            });

            if (newMessages.length > 100) {
                newMessages.splice(100, 1);
            }

            return {
                ...state,
                messages: JSON.parse(JSON.stringify(newMessages)),
            };
        }
        case RECEIVE_SYSTEM_CHAT_MESSAGE: {
            const newMessages = [...state.messages];
            newMessages.unshift({
                MessageId: action.systemMessage.MessageId,
                Player: action.systemMessage.Player,
                Text: action.systemMessage.Text,
                LikeCount: action.systemMessage.LikeCount,
                Liked: action.systemMessage.Liked,
                system: true,
                GameId: action.systemMessage.GameId,
                GameHash: action.systemMessage.GameHash,
                Multiplier: action.systemMessage.Multiplier,
                Salt: action.systemMessage.Salt,
                CrashedAt: action.systemMessage.CrashedAt,
                WonAmount: action.systemMessage.WonAmount,
                BuyIn: action.systemMessage.BuyIn,
            });

            if (newMessages.length > 100) {
                newMessages.splice(100, 1);
            }

            return {
                ...state,
                messages: JSON.parse(JSON.stringify(newMessages)),
            };
        }
        case UPDATE_CHAT_MESSAGE_LIKES: {
            const newMessages = [...state.messages].map(
                (message: ChatMessage) => {
                    if (
                        message.MessageId === action.messageLike.LikedMessageId
                    ) {
                        return {
                            ...message,
                            LikeCount: action.messageLike.LikeCount,
                            Liked: action.messageLike.Liked,
                        };
                    } else {
                        return message;
                    }
                }
            );
            return {
                ...state,
                messages: JSON.parse(JSON.stringify(newMessages)),
            };
        }
        case INITIALIZE_CHAT_HISTORY: {
            return {
                ...state,
                messages: JSON.parse(
                    JSON.stringify(action.chatHistory.reverse())
                ),
            };
        }
        default:
            return state;
    }
};

export default messengerReducer;
