import React, { FC } from 'react';
import styled from 'styled-components';
import { variables } from '../../constants/variables';

const StyledButton = styled.button<{
    height?: number;
    width?: number;
    background: string;
    blocked?: boolean;
}>`
    height: 100%;
    width: 100%;
    outline: 0;
    border: none;
    border-radius: 4px;
    font-size: 19px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.theme.fonts.mediatorNarrowCaps};
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    background: ${(props) => props.background};
    opacity: ${({ blocked }) => (blocked ? 0.5 : 1)};
    &:active {
        opacity: ${({ blocked }) => (blocked ? 0.5 : 0.8)};
    }
    &:disabled {
        background: ${(props) => props.theme.colors.transparent3};
        color: ${(props) => props.theme.colors.whiteGrayish};
    }
`;

const Button: FC<{
    text?: string;
    height?: number;
    width?: number;
    onClick?(): void;
    blockedAction?(): void;
    className?: string;
    background?: string;
    disabled?: boolean;
    blocked?: boolean;
}> = ({
    text,
    height,
    width,
    onClick,
    blockedAction,
    className,
    background,
    disabled,
    blocked,
}) => {
    return (
        <StyledButton
            className={className}
            height={height}
            width={width}
            onClick={blocked ? blockedAction : onClick}
            background={background ? background : variables.colors.vermillion}
            disabled={disabled}
            blocked={blocked}
        >
            {text}
        </StyledButton>
    );
};

export default Button;
