import React, { FC } from 'react';
import styled from 'styled-components';
import { KEYWORDS } from '../../../config/language/keywords';
import ArrowRight from '../../../components/Icons/ArrowRight';
import { motion } from 'framer-motion';
import useTranslate from '../../../config/language';

const ChatHeaderContainer = styled(motion.div)`
    position: relative;
    right: 0;
    font-size: 17px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    font-weight: 500;
    color: ${props => props.theme.colors.white};
    height: 45px;
    background: ${props => props.theme.colors.whiteTransparent};
    border-radius: 4px 4px 0px 0px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    margin-bottom: 8px;
    box-sizing: border-box;
    flex-shrink: 0;
`;

const IconWrapper = styled.div`
    width: 8px;
`;

const IconLeftContainer = styled.div`
    display: flex;
    width: 16px;
    justify-content: center;
`;

const ChatHeader: FC<{ handleOpen(open: boolean): void }> = ({
    handleOpen,
}) => {
    const translate = useTranslate();
    return (
        <ChatHeaderContainer onClick={() => handleOpen(false)}>
            {translate(KEYWORDS.Chat)}
            <IconLeftContainer>
                <IconWrapper>
                    <ArrowRight />
                </IconWrapper>
            </IconLeftContainer>
        </ChatHeaderContainer>
    );
};

export default ChatHeader;
