import React, { FC, memo, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useGameStatusState } from '../../config/store/state/app.state';
import { GAME_STATE } from '../../constants/interfaces/Game';
import useTranslate from '../../config/language';
import { KEYWORDS } from '../../config/language/keywords';
import { roundNumber } from '../../helpers/functions/round-number';

const Title: FC = () => {
    const game = useGameStatusState();
    const translate = useTranslate();
    const [title, setTitle] = useState('Boom');

    useEffect(() => {
        switch (game.state) {
            case GAME_STATE.RUNNING: {
                setTitle(
                    `${
                        (game.multiplier && roundNumber(game.multiplier)) ||
                        '1.00'
                    }x - BOOM!`
                );
                break;
            }
            case GAME_STATE.COUNTDOWN: {
                setTitle(`${translate(KEYWORDS.GameStart)} - BOOM!`);
                break;
            }
            case GAME_STATE.CRUSHED: {
                setTitle(
                    `${translate(KEYWORDS.Crashed)} ${game.multiplier} - BOOM!`
                );
                break;
            }
            default: {
                setTitle(`Boom`);
            }
        }
    }, [game.state, game.multiplier, translate]);

    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
            </Helmet>
        </HelmetProvider>
    );
};

export default memo(Title);
