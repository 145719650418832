import React, { FC } from 'react';
import styled from 'styled-components';
import DivArrow from '../Icons/DivArrow';

const StyledMultiplier = styled.div<{
    color: string;
}>`
    width: 100%;
    height: 100%;
    border-radius: 20px;
    font-family: ${props => props.theme.fonts.mediatorNarrowCaps};
    background: ${props => props.color};
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 45px;
    font-weight: bold;
    line-height: 0.87;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 8px;
    user-select: none;
`;

const DivArrowContainer = styled.div<{ color: string }>`
    color: ${props => props.color};
    position: absolute;
    left: -14px;
    top: 24px;
`;

const Multiplier: FC<{
    text: string;
    color: string;
    className?: string;
}> = ({ text, color, className }) => {
    return (
        <StyledMultiplier color={color} className={className}>
            {text}
            <DivArrowContainer color={color}>
                <DivArrow />
            </DivArrowContainer>
        </StyledMultiplier>
    );
};

export default Multiplier;
