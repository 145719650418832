import React, { FC } from 'react';
import styled from 'styled-components';
import Europebet from '../components/Icons/Europebet';
import FreeBetsContainer from '../features/freeBets';
import WebProfileSection from '../features/profile/WebProfileSection';
import { useMessengerState } from '../features/chat/store/state';

const StyledHeader = styled.div`
    height: 35px;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
`;

const StyledLogo = styled.div`
    height: 40px;
    width: 170px;
`;

const BoomSection = styled.div<{ isChatOpen: boolean }>`
    height: 100%;
    width: 192px;
    text-align: center;
    margin: auto;
    color: ${props => props.theme.colors.vermillion};
    font-size: 21px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    border-bottom: 2px solid;
    font-weight: bold;

    transform: translate(${({ isChatOpen }) => (isChatOpen ? 80 : 265)}px, 0);

    @media only screen and (max-width: ${1220}px) and (min-width: ${1000}px) {
        transform: translate(
            ${({ isChatOpen }) => (isChatOpen ? 35 : 175)}px,
            0
        );
    }

    @media only screen and (max-width: ${1340}px) and (min-width: ${1220}px) {
        transform: translate(
            ${({ isChatOpen }) => (isChatOpen ? 40 : 195)}px,
            0
        );
    }

    @media only screen and (max-width: ${1520}px) and (min-width: ${1340}px) {
        transform: translate(
            ${({ isChatOpen }) => (isChatOpen ? 40 : 200)}px,
            0
        );
    }

    @media only screen and (max-width: ${1840}px) and (min-width: ${1520}px) {
        transform: translate(
            ${({ isChatOpen }) => (isChatOpen ? 70 : 240)}px,
            0
        );
    }

    @media (max-width: ${1000}px) {
        transform: translate(${({ isChatOpen }) => (isChatOpen ? 0 : 40)}px, 0);
        width: 120px;
    }
`;

const Header: FC = () => {
    const chatOpen = useMessengerState().open;

    return (
        <StyledHeader>
            <StyledLogo>
                <Europebet />
            </StyledLogo>
            <BoomSection isChatOpen={chatOpen}>Boom</BoomSection>
            <FreeBetsContainer />
            <WebProfileSection />
        </StyledHeader>
    );
};

export default Header;
