export const turkish = {
    AccountBanned: 'Hesabınız engellenmiştir!',
    AllBets: 'Auto Bahis:',
    AlreadyRegistered: 'Bahis zaten yapmıştınız',
    Animation: 'Animasyon',
    AutoBet: 'OTOMATIK BAHIS',
    AutoBetting: 'Auto Bahis',
    AutoCashout: 'OTOMATIK PARA ÇEKME',
    AutoCashouting: 'Auto Bahis Bozdurma',
    Balance: 'BAKIYE',
    BalanceShort: 'BAL',
    Banned: 'KULLANICI ENGELLENMIŞTIR!',
    BaseBet: 'İLK BAHIS',
    Bet: 'BAHIS',
    BetMaximum: 'Bahis maksimum olmalıdır {}GEL',
    BetMustBe: 'Bahis en az olmalı',
    Bets: 'Bahisler',
    Boom: 'BOOM!',
    BustedJackpot: 'Kazanılmış jackpot',
    BuyIn: 'Bahis',
    Cashout: 'CASHOUT',
    CashoutFromGame: 'Cashout',
    CashoutMaxMustBe: 'Nakit Ödeme azami olmalıdır ',
    CashoutMustBe: 'Nakit Ödeme minimum olmalı 1.01',
    ChangeAvatar: 'Avatarı değiştir',
    Chat: 'SOHBET',
    ChatBlocked: 'Sohbetiniz engellenmiştir',
    CheckFair: '1. Oran doğrulaması nedir?',
    CheckHash: '3. Güvenilirlik kodu nasıl doğrulanır?',
    CheckResult: 'Sonucu kontrol et',
    Circulation: '#',
    Clear: 'CLEAR',
    Crashed: 'Oran',
    CurrentBets: 'GÜNCEL BAHISLER',
    Day: '24 saat',
    DearUsers: 'Teknik çalışmalar yapılmaktadır!',
    Error: 'Hata',
    ExampleForFair:
        'Oyun numarası - 9088 Oran - 1.06 Üretim süresi - 12/03/2019_16:09 Benzersiz oyun numarası - Fvv + 5TVHK2621A == Sonuç  olarak, oyunun anahtarı şu olacak: 9088_1.06_Boom_12 / 03 / 2019_16: 09_Fvv + 5TVHK2621A == Ve SHA256 algoritması tarafından oluşturulan güvenilirlik kodu: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d',
    Examples: 'Örnek:',
    FairGame: 'Adil oyun',
    Fill: 'Doldur',
    FreeBet: 'FreeBet',
    GameIsStopped: 'Oyun durduruldu',
    GameKey: '2. Oyun anahtarı ve güvenilirlik kodu ne anlama geliyor?',
    GameRules: 'Oyun kuralları',
    GameStart: 'Oyun başlamaktadır',
    GameStarted: 'Oyun başlamıştır! İyi şanslar!',
    GameStatusIsNotRegistering: 'Artık bahisler kabul edilmiyor',
    GameWillResume: 'Özgeçmişe kadar zaman',
    GotoHome: 'HOME',
    Hash: 'Hash',
    History: 'GEÇMIŞ',
    HowToCheckFair:
        "Bu yöntem, oyuncuların bahislerini kaydetmeden önce oyunun oranlarının (oyunun bittiği sayı) önceden belirlenmesini sağlamaktadır. Herhangi bir zamana önceden belirlenmiş 5 oyun vardır. Aşağıdakiler 5 geçmiş ve 5 gelecek turdan oluşan bir Hash Kod'dur.",
    HowToCheckGameKey:
        'Oyunun oranları (oyunun biteceği sayı)  5 oyun önceden belirlenmiştir. Oyun sunucusunda aşağıdaki parametrelerden oluşan bir oyun anahtarı oluşturulur: [Oyun Numarası] _ [Oranlar] _ [Boom] _ [Üretim Süresi] _ [Benzersiz Oyun Numarası]',
    HowToCheckHash:
        'Oyun bittikten sonra, aşağıda oyun anahtarı gösterilir ve onu herhangi bir online SHA256 hesap makinesinde şifreleyebilirsiniz ve daha sonra sonraki 5 oyun için verilen güvenilirlik koduyla karşılaştırabilirsiniz.',
    IncreaseBy: 'ARTTIRIN',
    Jackpot: 'JACKPOT',
    JackpotRuleBust:
        'Jackpot rastgele düşecektir ve tura katılan oyunculara eşit olarak bölünecektir:',
    JackpotRules: 'JACKPOT RULES',
    JackpotRulesBalance:
        'Kazançlar otomatik olarak oyuncunun bakiyesine yansıtılacaktır.',
    JackpotRulesBetCondition: 'En az 1₾ bahis yapmış',
    JackpotRulesCashoutCondition: '1.2’den aşağı orana bahis bozdurmamış',
    Key: 'Anahtar',
    LanguageChangeAcception: 'Geçekten dili değiştirmek istiyor musunuz',
    LanguageChangeWarning: 'Düğmesine tıklarsanız mevcut oyununuz bitecek',
    LastGames: 'Son oyunlar',
    LastHand: 'Son el',
    LastJackpot: 'Last Jackpot',
    LastTopSpins: 'TOP MULTIPLIERS IN 24 HOURS',
    LastTopWins: 'TOP WINS IN 24 HOURS',
    LeaveMessage: 'Yorum yapın',
    Login: 'GIRIŞ',
    LoginFailed: 'Giriş başarısız oldu',
    MainPage: 'Ana Sayfa',
    ManualBet: 'BAHIS',
    MinFreebetCashout: 'Min. odd 1.5',
    Month: 'aydaki',
    MultiplierCashout: 'Kazanma oranı',
    Music: 'Müzik',
    My: 'Benim',
    No: 'Hayır',
    NotEnoughMoney: 'Yetersiz bakiye',
    OnLoss: 'KAYIP EDILDIĞINDE',
    OnWin: 'KAZANILDIĞINDA',
    PlaceYourBet: 'BAHIS YAPIN',
    Player: 'oyuncu',
    Rating: 'TOP',
    Register: 'Bahis yapılması',
    Registration: 'BAHISLER KABUL EDILMEKTEDIR',
    Reload: 'Güncelleme',
    Result: 'Sonuç',
    ResultsHash: 'Sonuçlar karma kodu',
    ReturnToBaseBet: 'ILK BAHISSE GERI DÖNÜŞ YAPIN',
    ReturnToCash: 'Nakit paraya geri dönme',
    Round: 'Tur',
    Rules1: 'Bahis min. Miktarı - 0.10₾; Maksimum ise - 500₾.',
    Rules2: 'Roketin yüksekliği 1 ile sonsuzluk arasında değişir.',
    Rules3: 'Kazanma, havai fişeklerin yüksekliği ile çarpılan bahislere eşittir. Maks. Kazanma miktarı bahis başına - 50 000₾',
    Rules4: 'Oyuncunun patlamadan önce bahis bozdurmadığı takdirde bahsi kazanamamış olacaktır.',
    Rules5: 'Oyuncu aynı zamanda iki bahis yapabilir ve onları ayrı ayrı nakite de çevirebilir.',
    Rules6: 'Jackpot rastgele düşecektir ve tura katılan oyunculara eşit olarak bölünecektir:',
    Rules61: 'En az 1₾ bahis yapmış',
    Rules62: '1.2’den aşağı orana bahis bozdurmamış',
    Rules7: 'Kazançlar otomatik olarak oyuncunun bakiyesine yansıtılacaktır.',
    Rules8: 'Tüm oyuncular tur boyunca maksimum 100 000₾ kazanabilir. Tur başına tüm oyuncuların toplam kazancı 100 000₾’e ulaşılırsa, sistem otomatik olarak tüm bahisler nakitte çevirecektir.',
    Save: 'Save',
    ServerConnection: 'Server hatası',
    ServerError: 'Sunucu bağlantı hatası',
    SessionHasExpired: 'Oturum süresi doldu',
    SitesUpdate: 'Teknik çalışmalar yapılmaktadır!',
    Sound: 'Ses',
    Statistics: 'İstatistik',
    StopIf: 'DUR, EĞER BAHIS >',
    Submit: 'Submit',
    SuccessfulRegistration: 'Bahis kabul edilmiştir',
    TechnicalWork:
        'oyun yakında kesintisiz bir şekilde işlemeye devam edecektir.',
    TicketCashoutMustBe: 'Nakit Ödeme minimum olmalı 1.5',
    Time: 'Zaman',
    TopSpins: 'Oran',
    TopWins: 'Top Kazançlar',
    UnknowError: 'Server hatası',
    Unregister: 'Bahis iptali',
    UnregisterFreeBet: 'FreeBet iptali',
    Unregistered: 'Bahis iptal olmuştur',
    User: 'İsim',
    UserName: 'İsim',
    UserShort: 'İsim',
    WaitingForNextRound: 'You Are Waiting For Next Round',
    Win: 'KAZANMA',
    WinAmount: 'Kazanma',
    WinMultiplier: 'Win. Mult',
    Winners: 'Winners',
    WrongAmount: 'Yetersiz bakiye',
    WrongConfiguration: 'Yanlış yapılandırma',
    Year: 'Yıldaki',
    Yes: 'Evet',
    YouAreNotRegistered: 'Kayıtlı değilsin',
    EnterText: 'Metin Girin',
    PlayerNotFound: 'Player Not Found',
    Jan: 'Jan.',
    Feb: 'Feb.',
    Mar: 'Mar.',
    Apr: 'Apr.',
    May: 'May',
    June: 'June',
    July: 'July',
    Aug: 'Aug.',
    Sept: 'Sept.',
    Oct: 'Oct.',
    Nov: 'Nov.',
    Dec: 'Dec.',
};
