import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

export const animateStars = keyframes`
  from {
        transform: translateY(-300px);
        opacity: 0.8;
  }

  to {
        transform: translateY(3500px);
        opacity: 0;
  }
`;

export const animateFirstLayerStars = keyframes`
    from {
        transform: translateY(-2000px);
    }

    to {
        transform: translateY(2000px);
    }
`;

export const animateSecondLayerStars = keyframes`
    from {
        transform: translateY(-2800px);
    }

    to {
        transform: translateY(1200px);
    }
`;

const StyledStars = styled.div`
    transform: translateY(-300px);
    background: transparent;
    opacity: 0.8;
    height: 1px;
    width: 1.2px;
    box-shadow: 1226px 1621px #fff, 1021px 1311px #fff, 589px 396px #fff,
        106px 950px #fff, 1628px 685px #fff, 1982px 1853px #fff,
        683px 1196px #fff, 885px 147px #fff, 140px 572px #fff, 619px 1786px #fff,
        158px 727px #fff, 1051px 1759px #fff, 304px 515px #fff,
        1107px 1063px #fff, 1409px 293px #fff, 844px 1928px #fff,
        1297px 766px #fff, 769px 53px #fff, 1796px 1499px #fff,
        1096px 1588px #fff, 6px 1066px #fff, 954px 1823px #fff,
        449px 1318px #fff, 742px 324px #fff, 1360px 430px #fff, 474px 517px #fff,
        1638px 553px #fff, 65px 362px #fff, 1668px 305px #fff, 618px 1791px #fff,
        1248px 698px #fff, 526px 1505px #fff, 443px 1738px #fff,
        968px 744px #fff, 1509px 1343px #fff, 940px 891px #fff,
        1426px 313px #fff, 86px 1904px #fff, 1852px 914px #fff,
        1774px 1138px #fff, 1406px 378px #fff, 82px 384px #fff,
        1195px 1561px #fff, 472px 1994px #fff, 887px 708px #fff,
        1078px 680px #fff, 1715px 140px #fff, 333px 1224px #fff,
        1538px 412px #fff, 1476px 644px #fff, 976px 149px #fff,
        938px 1127px #fff, 531px 1088px #fff, 996px 403px #fff,
        279px 1233px #fff, 1761px 1297px #fff, 1126px 1184px #fff,
        1417px 375px #fff, 1085px 1137px #fff, 669px 987px #fff,
        1149px 1963px #fff, 1097px 880px #fff, 1591px 85px #fff,
        1858px 884px #fff, 1187px 1483px #fff, 1246px 415px #fff,
        126px 516px #fff, 1824px 672px #fff, 178px 250px #fff, 1675px 146px #fff,
        1827px 816px #fff, 803px 992px #fff, 1703px 1664px #fff,
        1658px 970px #fff, 1607px 1896px #fff, 268px 499px #fff,
        465px 395px #fff, 287px 468px #fff, 980px 1675px #fff,
        1959px 1989px #fff, 429px 1263px #fff, 1419px 1703px #fff,
        302px 335px #fff, 1470px 698px #fff, 431px 1784px #fff,
        1397px 1168px #fff, 585px 143px #fff, 1163px 455px #fff,
        1007px 1355px #fff, 764px 1147px #fff, 1528px 1835px #fff,
        1298px 1629px #fff, 438px 971px #fff, 1940px 1307px #fff,
        1767px 1080px #fff, 1700px 1582px #fff, 911px 709px #fff,
        1733px 1916px #fff, 1650px 1153px #fff, 1491px 1908px #fff,
        1221px 1065px #fff, 389px 749px #fff, 576px 602px #fff, 84px 1595px #fff,
        1887px 1748px #fff, 1293px 1674px #fff, 1372px 986px #fff,
        834px 1280px #fff, 241px 777px #fff, 1122px 1540px #fff,
        908px 1032px #fff, 1715px 14px #fff, 732px 1014px #fff,
        1888px 766px #fff, 1433px 1174px #fff, 1273px 1889px #fff,
        337px 301px #fff, 1449px 1878px #fff, 946px 1411px #fff,
        965px 181px #fff, 1508px 537px #fff, 977px 1648px #fff,
        696px 1932px #fff, 601px 305px #fff, 1734px 186px #fff,
        1962px 1776px #fff, 965px 791px #fff, 1563px 422px #fff,
        1427px 822px #fff, 1540px 599px #fff, 1246px 1681px #fff,
        1271px 1136px #fff, 1411px 641px #fff, 1108px 1981px #fff,
        961px 1884px #fff, 788px 631px #fff, 172px 783px #fff, 1729px 455px #fff,
        1682px 1051px #fff, 911px 1455px #fff, 1652px 489px #fff,
        880px 94px #fff, 59px 747px #fff, 417px 223px #fff, 332px 1397px #fff,
        699px 659px #fff, 693px 728px #fff, 1232px 208px #fff, 1514px 774px #fff,
        1070px 633px #fff, 1768px 165px #fff, 1261px 1666px #fff,
        35px 1979px #fff, 54px 1408px #fff, 820px 745px #fff, 1732px 147px #fff,
        1282px 1121px #fff, 1640px 1px #fff, 1932px 632px #fff, 895px 31px #fff,
        1751px 266px #fff, 746px 54px #fff, 1197px 986px #fff, 1032px 658px #fff,
        234px 1526px #fff, 1267px 1176px #fff, 1280px 357px #fff,
        1333px 381px #fff, 1739px 1375px #fff, 888px 1325px #fff,
        704px 550px #fff, 20px 359px #fff, 987px 1218px #fff, 794px 1724px #fff,
        774px 590px #fff, 1024px 227px #fff, 1487px 400px #fff,
        1446px 627px #fff, 984px 1342px #fff, 966px 703px #fff,
        105px 1673px #fff, 1225px 1267px #fff, 119px 1202px #fff,
        1458px 273px #fff, 1057px 1167px #fff, 116px 735px #fff,
        665px 1021px #fff, 476px 1830px #fff, 145px 906px #fff, 502px 596px #fff,
        1629px 645px #fff, 728px 1972px #fff, 342px 605px #fff,
        1331px 179px #fff, 707px 1684px #fff, 388px 1757px #fff, 805px 73px #fff,
        659px 180px #fff, 848px 915px #fff, 182px 1550px #fff, 1955px 155px #fff,
        973px 1546px #fff, 1061px 1579px #fff, 52px 687px #fff,
        1104px 1352px #fff, 408px 1386px #fff, 1888px 1692px #fff,
        956px 1018px #fff, 1355px 1568px #fff, 1505px 1550px #fff,
        1182px 705px #fff, 959px 1050px #fff, 498px 1432px #fff,
        301px 647px #fff, 443px 334px #fff, 1402px 461px #fff, 147px 1270px #fff,
        391px 1572px #fff, 1017px 341px #fff, 68px 1770px #fff, 235px 860px #fff,
        505px 816px #fff, 1312px 986px #fff, 1079px 1293px #fff,
        324px 611px #fff, 1179px 1011px #fff, 277px 434px #fff,
        1767px 252px #fff, 1775px 1487px #fff, 1705px 19px #fff,
        326px 721px #fff, 378px 70px #fff, 895px 1736px #fff, 1570px 365px #fff,
        513px 615px #fff, 1092px 147px #fff, 1318px 1932px #fff,
        1592px 1734px #fff, 699px 918px #fff, 111px 388px #fff,
        1067px 890px #fff, 1169px 332px #fff, 358px 481px #fff,
        1630px 750px #fff, 179px 1787px #fff, 355px 489px #fff, 852px 821px #fff,
        868px 364px #fff, 386px 1700px #fff, 799px 1420px #fff,
        1472px 644px #fff, 1552px 905px #fff, 48px 1998px #fff, 1850px 51px #fff,
        416px 678px #fff, 759px 1046px #fff, 1451px 1951px #fff,
        101px 1610px #fff, 1469px 912px #fff, 1214px 1292px #fff,
        1368px 1018px #fff, 484px 470px #fff, 816px 322px #fff,
        1821px 1326px #fff, 327px 1936px #fff, 752px 550px #fff,
        1215px 1924px #fff, 180px 122px #fff, 784px 1924px #fff,
        1597px 336px #fff, 248px 1429px #fff, 4px 181px #fff, 1801px 1613px #fff,
        1581px 1719px #fff, 640px 1391px #fff, 960px 302px #fff,
        1483px 1207px #fff, 520px 1119px #fff, 25px 1754px #fff,
        545px 317px #fff, 1573px 751px #fff, 1415px 2px #fff, 101px 761px #fff,
        679px 291px #fff, 1785px 676px #fff, 1256px 136px #fff, 855px 197px #fff,
        1399px 1973px #fff, 1243px 429px #fff, 1281px 571px #fff,
        839px 531px #fff, 789px 1980px #fff, 877px 783px #fff,
        1526px 1850px #fff, 644px 1283px #fff, 1982px 1953px #fff,
        1109px 1232px #fff, 1532px 643px #fff, 823px 1287px #fff,
        667px 34px #fff, 24px 1469px #fff, 1029px 1130px #fff, 1645px 254px #fff,
        1054px 1000px #fff, 1341px 254px #fff, 184px 188px #fff,
        1160px 1521px #fff, 348px 1083px #fff, 1436px 1796px #fff,
        441px 361px #fff, 1239px 575px #fff, 1055px 140px #fff,
        1552px 502px #fff, 607px 864px #fff, 967px 1883px #fff,
        1407px 1955px #fff, 405px 1148px #fff, 604px 992px #fff,
        1116px 506px #fff, 13px 832px #fff, 295px 189px #fff, 964px 1175px #fff,
        1988px 52px #fff, 935px 209px #fff, 17px 142px #fff, 770px 333px #fff,
        682px 633px #fff, 510px 466px #fff, 366px 79px #fff, 199px 954px #fff,
        882px 277px #fff, 744px 655px #fff, 37px 1659px #fff, 1217px 1885px #fff,
        1107px 597px #fff, 1060px 1335px #fff, 773px 601px #fff,
        1939px 1632px #fff, 1607px 1774px #fff, 1531px 1550px #fff,
        1042px 60px #fff, 850px 1502px #fff, 1670px 492px #fff,
        1357px 1654px #fff, 1279px 1521px #fff, 633px 1956px #fff,
        1122px 1981px #fff, 661px 657px #fff, 521px 1132px #fff,
        1593px 813px #fff, 1204px 1463px #fff, 1112px 1495px #fff,
        1755px 367px #fff, 1685px 1805px #fff, 1982px 196px #fff,
        484px 51px #fff, 1041px 330px #fff, 1487px 563px #fff, 311px 1872px #fff,
        330px 976px #fff, 6px 1754px #fff, 1447px 1182px #fff, 1926px 148px #fff,
        1640px 490px #fff, 1548px 1357px #fff, 1052px 1340px #fff,
        1083px 56px #fff, 1890px 339px #fff, 277px 1609px #fff,
        892px 1792px #fff, 1322px 86px #fff, 1509px 1515px #fff,
        1650px 1722px #fff, 334px 497px #fff, 888px 143px #fff,
        1325px 259px #fff, 1330px 1505px #fff, 1374px 645px #fff,
        1184px 937px #fff, 911px 1044px #fff, 32px 1257px #fff,
        297px 1884px #fff, 1031px 963px #fff, 411px 916px #fff,
        243px 1305px #fff, 898px 200px #fff, 1550px 156px #fff, 159px 716px #fff,
        240px 1130px #fff, 1046px 1905px #fff, 1405px 1277px #fff,
        275px 124px #fff, 964px 795px #fff, 1270px 1387px #fff,
        1391px 1880px #fff, 484px 1027px #fff, 1681px 1706px #fff,
        1665px 697px #fff, 1073px 1078px #fff, 1808px 1313px #fff,
        1669px 1266px #fff, 1965px 747px #fff, 1734px 972px #fff,
        1549px 1434px #fff, 945px 146px #fff, 1211px 1383px #fff,
        1921px 1637px #fff, 271px 1725px #fff, 739px 1725px #fff,
        1634px 1827px #fff, 961px 1131px #fff, 513px 839px #fff,
        1553px 1725px #fff, 82px 1667px #fff, 3px 1797px #fff, 517px 1511px #fff,
        1644px 929px #fff, 120px 1638px #fff, 579px 1168px #fff,
        147px 680px #fff, 61px 1146px #fff, 884px 598px #fff, 630px 1324px #fff,
        1709px 1340px #fff, 1332px 1142px #fff, 1990px 1397px #fff,
        854px 1527px #fff, 1293px 634px #fff, 1063px 506px #fff,
        1361px 668px #fff, 926px 347px #fff, 145px 1031px #fff,
        1289px 1519px #fff, 1977px 1560px #fff, 70px 1353px #fff,
        653px 1634px #fff, 1182px 340px #fff, 1264px 1127px #fff,
        1246px 40px #fff, 428px 691px #fff, 1531px 1817px #fff,
        431px 1906px #fff, 1406px 1665px #fff, 597px 1588px #fff,
        1273px 355px #fff, 995px 1018px #fff, 1319px 528px #fff,
        1454px 715px #fff, 1587px 1052px #fff, 921px 193px #fff,
        1012px 479px #fff, 689px 1019px #fff, 1307px 420px #fff,
        1153px 108px #fff, 1343px 775px #fff, 1236px 1061px #fff,
        1117px 1099px #fff, 308px 533px #fff, 1262px 509px #fff, 830px 24px #fff,
        426px 695px #fff, 1148px 1397px #fff, 1016px 1719px #fff,
        1142px 1280px #fff, 1841px 310px #fff, 1890px 1708px #fff,
        1399px 589px #fff, 913px 1291px #fff, 955px 1445px #fff,
        431px 491px #fff, 580px 1002px #fff, 796px 186px #fff, 445px 1634px #fff,
        1543px 1377px #fff, 1853px 57px #fff, 34px 620px #fff, 1056px 923px #fff,
        1027px 134px #fff, 1248px 1252px #fff, 756px 1855px #fff,
        143px 654px #fff, 253px 347px #fff, 181px 943px #fff, 1044px 1774px #fff,
        367px 1925px #fff, 1125px 854px #fff, 1276px 141px #fff,
        422px 413px #fff, 1261px 973px #fff, 1030px 1349px #fff,
        1349px 1850px #fff, 552px 1286px #fff, 606px 888px #fff,
        464px 1922px #fff, 1799px 260px #fff, 1984px 788px #fff,
        1210px 450px #fff, 1288px 1530px #fff, 911px 1896px #fff, 689px 4px #fff,
        806px 162px #fff, 1201px 604px #fff, 908px 531px #fff, 137px 1123px #fff,
        1165px 496px #fff, 1567px 1797px #fff, 614px 504px #fff,
        1551px 1165px #fff, 1309px 1032px #fff, 1321px 26px #fff,
        1372px 326px #fff, 1762px 1159px #fff, 728px 5px #fff, 1364px 459px #fff,
        1333px 918px #fff, 1082px 1732px #fff, 286px 319px #fff,
        1636px 1220px #fff, 350px 966px #fff, 124px 219px #fff,
        184px 1654px #fff, 1195px 160px #fff, 1690px 737px #fff,
        1386px 472px #fff, 1522px 1911px #fff, 1082px 1129px #fff,
        491px 67px #fff, 826px 393px #fff, 643px 632px #fff, 1934px 1461px #fff,
        1325px 316px #fff, 934px 1722px #fff, 1044px 879px #fff,
        1109px 1482px #fff, 1040px 642px #fff, 801px 509px #fff,
        1352px 1583px #fff, 160px 339px #fff, 1111px 1731px #fff,
        1650px 1190px #fff, 1804px 1019px #fff, 745px 604px #fff,
        1391px 1108px #fff, 344px 708px #fff, 395px 111px #fff,
        913px 1180px #fff, 537px 1036px #fff, 1048px 1611px #fff,
        1926px 155px #fff, 392px 193px #fff, 1980px 1465px #fff,
        1853px 1349px #fff, 204px 1317px #fff, 1342px 1956px #fff,
        785px 1564px #fff, 1478px 612px #fff, 1459px 491px #fff,
        1767px 265px #fff, 1032px 495px #fff, 946px 1196px #fff,
        607px 285px #fff, 887px 1940px #fff, 1618px 962px #fff, 85px 801px #fff,
        1828px 525px #fff, 523px 1927px #fff, 280px 1676px #fff,
        551px 1133px #fff, 1888px 936px #fff, 875px 863px #fff,
        395px 1954px #fff, 155px 590px #fff, 1924px 262px #fff,
        1039px 1430px #fff, 1356px 1816px #fff, 978px 671px #fff,
        1968px 1044px #fff, 1693px 15px #fff, 266px 1506px #fff,
        1061px 224px #fff, 690px 1132px #fff, 1149px 313px #fff,
        744px 898px #fff, 848px 1851px #fff, 296px 761px #fff, 55px 624px #fff,
        573px 131px #fff, 483px 1869px #fff, 157px 1304px #fff, 496px 489px #fff,
        729px 1730px #fff, 163px 1023px #fff, 1065px 1426px #fff,
        1058px 865px #fff, 1204px 1762px #fff, 73px 527px #fff,
        668px 1237px #fff, 1px 259px #fff, 1027px 107px #fff, 1347px 842px #fff,
        720px 819px #fff, 97px 1047px #fff, 474px 1262px #fff,
        1406px 1163px #fff, 1620px 1312px #fff, 292px 423px #fff,
        1579px 1735px #fff, 687px 305px #fff, 1810px 1822px #fff,
        1499px 241px #fff, 1578px 1308px #fff, 770px 1441px #fff,
        398px 646px #fff, 1186px 1423px #fff, 6px 201px #fff, 1684px 1057px #fff,
        1252px 1352px #fff, 1649px 317px #fff, 746px 1777px #fff,
        537px 97px #fff, 347px 1613px #fff, 1709px 1896px #fff, 134px 957px #fff,
        755px 340px #fff, 107px 1057px #fff, 263px 1459px #fff,
        1559px 1124px #fff, 1082px 19px #fff, 557px 795px #fff, 596px 455px #fff,
        1877px 1535px #fff, 457px 320px #fff, 532px 270px #fff,
        773px 1991px #fff, 644px 1840px #fff, 777px 1413px #fff,
        726px 203px #fff, 20px 740px #fff, 655px 909px #fff, 396px 1998px #fff,
        235px 954px #fff, 1737px 264px #fff, 670px 1532px #fff, 498px 159px #fff,
        540px 1997px #fff, 1792px 1327px #fff, 880px 1023px #fff,
        1917px 1938px #fff, 1859px 148px #fff, 1147px 1781px #fff,
        428px 1876px #fff, 1952px 605px #fff, 655px 80px #fff,
        1500px 1545px #fff, 1656px 644px #fff, 615px 247px #fff,
        795px 1881px #fff, 1750px 586px #fff, 148px 1490px #fff,
        1434px 74px #fff, 417px 1969px #fff, 962px 1526px #fff, 835px 962px #fff,
        330px 1767px #fff, 1557px 435px #fff, 240px 341px #fff,
        1857px 1589px #fff, 765px 281px #fff, 1452px 405px #fff,
        1480px 1724px #fff, 950px 1094px #fff, 710px 1388px #fff,
        1333px 1919px #fff, 706px 1589px #fff, 1476px 1465px #fff,
        744px 291px #fff, 123px 870px #fff, 1406px 428px #fff, 727px 1609px #fff,
        1149px 1838px #fff, 78px 169px #fff, 1088px 284px #fff, 670px 399px #fff,
        711px 1923px #fff, 42px 1720px #fff, 667px 570px #fff, 1274px 693px #fff,
        1056px 644px #fff, 1937px 793px #fff, 1200px 602px #fff,
        1493px 755px #fff, 545px 870px #fff, 887px 270px #fff, 1290px 164px #fff,
        265px 1185px #fff;
`;

const Stars: FC<{ className?: string }> = ({ className }) => {
    return <StyledStars className={className} />;
};

export default Stars;
