import React, { FC, memo } from 'react';
import GameRunning from '../state/Running';
import GameCoolDown from '../state/Cooldown';
import GameExploding from '../state/Exploding';
import { GAME_STATE } from '../../../constants/interfaces/Game';

const RenderGame: FC<{ gameState?: GAME_STATE }> = ({ gameState }) => {
    return (
        <>
            {(gameState === GAME_STATE.LAUNCHING ||
                gameState === GAME_STATE.RUNNING) && <GameRunning />}
            {gameState === GAME_STATE.COUNTDOWN && <GameCoolDown />}
            <GameExploding show={gameState === GAME_STATE.CRUSHED} />
        </>
    );
};

export default memo(RenderGame);
