import React, { FC } from 'react';
import styled from 'styled-components';
import ArrowUp from '../Icons/ArrowUp';
import ArrowDown from '../Icons/ArrowDown';
import InputNumber from 'rc-input-number';

const StyledNumberInput = styled.div<{ fontSize: number; disabled?: boolean }>`
    height: 100%;
    width: 100%;
    min-height: 30px;
    background: ${(props) => props.theme.colors.transparent};
    position: relative;
    & .rc-input-number-input-wrap {
        height: 100%;
        display: block;
        border: none;
        & > input {
            padding: 0 25px 0 16px;
            font-weight: bold;
            border: none;
            color: ${(props) => props.theme.colors.white};
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background: transparent;
            outline: none;
            font-size: ${(props) => props.fontSize}px;
            font-family: ${(props) => props.theme.fonts.mediatorNarrow};
            &:disabled {
                opacity: 0.5;
                font-size: 21px;
                color: ${(props) => props.theme.colors.whiteGrayish} !important;
                text-shadow: none;
            }
        }
    }

    @media (max-height: ${550}px), (max-width: ${600}px) {
        & .rc-input-number-input-wrap {
            & > input {
                font-size: 16px;
            }
        }
    }
    @media (max-width: ${1100}px) {
        & .rc-input-number-input-wrap {
            & > input {
                font-size: 16px;
                padding: 0 20px 0 4px;
            }
        }
    }
`;

const Input = styled(InputNumber)`
    height: 100%;
    width: 100%;
`;

const Arrows = styled.div<{ disabled?: boolean }>`
    position: absolute;
    width: 30px;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.disabled &&
        `
        opacity: 0.5;
        pointer-events: none;
    `}
`;

const ArrowContainer = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.transparent2};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 2px 0;
    &:active {
        opacity: 0.9;
    }
`;

const ArrowIcon = styled.div`
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function formatDecimalsNumber(value: number | null) {
    if (!value) return 0;
    return Number(Math.round(parseFloat(value + 'e' + 2)) + 'e-' + 2);
}

function formatNumber(num: number | null, character: string) {
    return formatDecimalsNumber(num) + ` ${character}`;
}

const NumberInput: FC<{
    value: number;
    onChange(value: number | null): void;
    step?: number;
    disabled?: boolean;
    minValue?: number;
    maxValue?: number;
    fontSize?: number;
    character?: string;
    className?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}> = ({
    value,
    onChange,
    step = 0.1,
    disabled,
    minValue = 0.1,
    maxValue,
    fontSize = 24,
    character = '₾',
    className,
    onFocus,
}) => {
    const handleInputChange = (val: number | null) => {
        onChange(val);
    };

    const handleIncrement = () => {
        onChange(Number(value + step));
    };

    const handleDecrement = () => {
        onChange(Number(value - step));
    };

    return (
        <StyledNumberInput
            disabled={disabled}
            fontSize={fontSize}
            className={className}
            data-value={value}
        >
            <Input
                value={value}
                pattern="[0-9]*"
                formatter={(val) => {
                    return `${formatNumber(Number(val), character)}`;
                }}
                step={step}
                min={minValue}
                max={maxValue}
                onFocus={onFocus}
                onChange={(value) => {
                    handleInputChange(Number(value));
                }}
                disabled={disabled}
            />
            <Arrows disabled={disabled}>
                <ArrowContainer
                    onClick={!disabled ? handleIncrement : undefined}
                >
                    <ArrowIcon>
                        <ArrowUp />
                    </ArrowIcon>
                </ArrowContainer>
                <ArrowContainer
                    onClick={!disabled ? handleDecrement : undefined}
                >
                    <ArrowIcon>
                        <ArrowDown />
                    </ArrowIcon>
                </ArrowContainer>
            </Arrows>
        </StyledNumberInput>
    );
};

export default NumberInput;
