import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Explosion from '../../spaceship/states/exploding';
import { SoundPlayer } from '../../soundPlayer/soundPlayer';
import { useGameConfigs } from '../../../config/store/state/app.state';
import { EXPLOSION } from '../../spaceship/tools';

const CoolDownContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;

    @media (max-width: ${450}px) {
        margin-right: 100px;
    }
`;

const GameExploding: FC<{ show: boolean }> = ({ show }) => {
    const voiceEffects = useGameConfigs().voiceEffects;
    const [images, setImages] = useState<any>([]);
    useEffect(() => {
        setImages(
            EXPLOSION.map((src) => {
                const img = new Image();
                img.src = src;
                return <img src={src} alt="explosion" />;
            })
        );
    }, []);

    useEffect(() => {
        const sound = new SoundPlayer();
        if (!voiceEffects || !show) {
            return;
        }
        sound.playExplosionSound();
        return () => {
            sound.stopExplosionSound();
        };
    }, [voiceEffects, show]);

    return (
        <CoolDownContainer>
            <Explosion explosion={images} show={show} />
        </CoolDownContainer>
    );
};

export default GameExploding;
