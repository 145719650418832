import React, { FC } from 'react';
import styled from 'styled-components';
import Stars, {
    animateFirstLayerStars,
    animateSecondLayerStars,
    animateStars,
} from './Stars';
import {
    useGameConfigs,
    useGameStatusState,
} from '../../config/store/state/app.state';
import { GAME_STATE } from '../../constants/interfaces/Game';

const StyledStarsContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    bottom: 0;
    right: 15px;
`;

const StyledStars = styled(Stars)`
    animation: ${animateStars} 10s linear forwards;
`;

const StyledFirstLayerStars = styled(Stars)`
    transform: translateY(-2000px);
    width: 1px;
    animation: ${animateFirstLayerStars} 10s linear infinite;
`;

const StyledSecondLayerStars = styled(Stars)`
    transform: translateY(-4000px);
    width: 1px;
    animation: ${animateSecondLayerStars} 10s linear infinite;
    animation-delay: 3s;
`;

const StarsContainer: FC<{ disabled?: boolean }> = ({ disabled }) => {
    const gameState = useGameStatusState().state;
    const animate = useGameConfigs().animate;

    return (
        <StyledStarsContainer>
            {!disabled && animate && gameState === GAME_STATE.RUNNING ? (
                <>
                    <StyledStars />
                    <StyledFirstLayerStars />
                    <StyledSecondLayerStars />
                </>
            ) : (
                <Stars />
            )}
        </StyledStarsContainer>
    );
};

export default StarsContainer;
