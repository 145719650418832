import React, { memo } from 'react';
import Title from './features/title';
import BackgroundContainer from './components/Background';
import StarsContainer from './components/Stars';
import { AudioPlayer } from './features/audioPlayer';
import Header from './layout/Header';
import Sidebar from './layout/Sidebar';
import SidebarList from './features/topBoards';
import Content from './layout/Content';
import OddsList from './features/oddsList';
import GameContainer from './features/game';
import ActionPanel from './features/actionPanel';
import Chat from './features/chat';
import styled from 'styled-components';
import useBackgroundsMusic from './components/BackgroundMusic';
import { useIsMobile } from './helpers/hooks/useIsMobile';
import MobileHeader from './layout/MobileHeader';
import ErrorModal from './components/Modals';
import { useErrorsState } from './config/store/state/app.state';

const AppContainer = styled.div`
    height: 100%;
    display: flex;
    color: white;
    overflow: auto;
`;

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
`;

const StyledBody = styled.div`
    display: flex;
    flex: 1;
    @media (max-width: ${1000}px) {
        flex-direction: column;
    }
`;

const Body = styled.div`
    display: flex;
    flex: 1;
`;

const Game = () => {
    const errors = useErrorsState();
    useBackgroundsMusic();

    const isMobile = useIsMobile();
    return (
        <AppContainer>
            {errors.isError && <ErrorModal type={errors.type} />}
            <Title />
            <BackgroundContainer />
            <StarsContainer />
            <AudioPlayer />
            <StyledContainer>
                {isMobile ? <MobileHeader /> : <Header />}
                <Body>
                    <StyledBody>
                        <Sidebar>
                            <SidebarList />
                        </Sidebar>
                        <Content>
                            <OddsList />
                            <GameContainer />
                            <ActionPanel />
                        </Content>
                    </StyledBody>
                    <Chat />
                </Body>
            </StyledContainer>
        </AppContainer>
    );
};

export default memo(Game);
