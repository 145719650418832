import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { RegisterState, useBoards } from '../../config/store/state/app.state';
import { useToggleEnableFreeBetsService } from '../../config/store/services';
import { useBalance } from '../profile/configs/store/state';
import FreeBetsHandler from './FreeBetsHandler';
import FreeBetsToggler from './FreeBetsToggler';

const Container = styled.div`
    width: 80px;
    margin: 0 16px;
`;

const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 3;
    margin: 0 4px;
`;

const FreeBetsContainer = () => {
    const freeBetsBalance = useBalance()?.FreeSpinBalance || [];
    const freeSpinTotalCount = freeBetsBalance
        .map(freeSpin => freeSpin.Count)
        .reduce((a, b) => a + b, 0);
    const isFreeBetsBalanceEmpty =
        freeBetsBalance.length < 1 || freeSpinTotalCount < 1;
    const handleToggleFreeBets = useToggleEnableFreeBetsService();

    const boards = useBoards();

    useEffect(() => {
        if (isFreeBetsBalanceEmpty) {
            const boardRegisterState = boards.filter(
                board => board.PanelIndex === 3 || board.PanelIndex === 4
            );

            if (
                boardRegisterState[0].registerState ===
                    RegisterState.UNREGISTERED &&
                boardRegisterState[1].registerState ===
                    RegisterState.UNREGISTERED
            ) {
                handleToggleFreeBets(false);
            }
        }
    }, [boards, handleToggleFreeBets, isFreeBetsBalanceEmpty]);

    if (isFreeBetsBalanceEmpty) {
        return <span />;
    }

    return (
        <Container>
            <ToggleContainer>
                <FreeBetsHandler />
                <FreeBetsToggler />
            </ToggleContainer>
        </Container>
    );
};

export default memo(FreeBetsContainer);
