import React, { memo } from 'react';
import ActionBoard from './ActionBoard';
import { useBoards } from '../../../../config/store/state/app.state';
import {
    useBoardAddedService,
    useBoardRemovedService,
} from '../../../../config/store/services';

const BOARD_ONE_PANEL_INDEX = 0;
const BOARD_TWO_PANEL_INDEX = 1;

const ActionBoardContainer = () => {
    const boards = useBoards();
    const handleAddBoard = useBoardAddedService();
    const handleRemoveBoard = useBoardRemovedService();

    const firstBoard = boards.filter(
        (board) => board.PanelIndex === BOARD_ONE_PANEL_INDEX
    )[0];
    const secondBoard = boards.filter(
        (board) => board.PanelIndex === BOARD_TWO_PANEL_INDEX
    )[0];
    const boardsLength = boards.filter(
        (board) =>
            board.PanelIndex === BOARD_ONE_PANEL_INDEX ||
            board.PanelIndex === BOARD_TWO_PANEL_INDEX
    ).length;

    return (
        <>
            {firstBoard && (
                <ActionBoard
                    activeBoards={boardsLength}
                    boardIndex={firstBoard.PanelIndex}
                    addBoard={() => handleAddBoard(BOARD_TWO_PANEL_INDEX)}
                    removeBoard={() => handleRemoveBoard(BOARD_ONE_PANEL_INDEX)}
                />
            )}
            {secondBoard && (
                <ActionBoard
                    activeBoards={boardsLength}
                    boardIndex={secondBoard.PanelIndex}
                    addBoard={() => handleAddBoard(BOARD_ONE_PANEL_INDEX)}
                    removeBoard={() => handleRemoveBoard(BOARD_TWO_PANEL_INDEX)}
                />
            )}
        </>
    );
};

export default memo(ActionBoardContainer);
