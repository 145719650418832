export const georgian = {
    AccountBanned: 'თქვენი ანგარიში დაბლოკილია!',
    AllBets: 'ყველა ფსონი:',
    AlreadyRegistered: 'თქვენ უკვე დადებული გაქვთ ფსონი',
    Animation: 'ანიმაცია',
    AutoBet: 'ავტომატური',
    AutoBetting: 'ავტო ფსონი',
    AutoCashout: 'ავტო ქეშაუთი',
    AutoCashouting: 'ავტო განაღდება',
    Balance: 'ბალანსი',
    BalanceShort: 'ბალ',
    Banned: 'თქვენ დაბლოკილი ხართ',
    BaseBet: 'საწყისი ფსონი',
    Bet: 'ფსონი',
    BetMaximum: 'ბეთი უნდა იყოს მაქსიმუმ {}₾',
    BetMustBe: 'ბეთი უნდა იყოს მინიმუმ ',
    Bets: 'ფსონები',
    Boom: 'BOOM!',
    BustedJackpot: 'გათამაშებული ჯეკპოტი',
    BuyIn: 'ფსონი',
    Cashout: 'ქეშაუთი',
    CashoutFromGame: 'ქეშაუთი',
    CashoutMaxMustBe: 'ქეშაუთი უნდა იყოს მაქსიმუმ',
    CashoutMustBe: 'ქეშაუთი უნდა იყოს მინუმუმ 1.01',
    ChangeAvatar: 'ავატარის შეცვლა',
    Chat: 'ჩატი',
    ChatBlocked: 'თქვენი ჩატი დაბლოკილია',
    CheckFair: '1. რა არის კოეფიციენტის გადამოწმება?',
    CheckHash: '3. როგორ გადავამოწმოთ სანდოობის კოდი?',
    CheckResult: 'შედეგის შემოწმება',
    Circulation: '#',
    Clear: 'გასუფთავება',
    Crashed: 'კოეფ.',
    CurrentBets: 'ფსონები',
    Day: '24 საათის',
    DearUsers: 'ბოდიშს გიხდით შეფერხებისთვის',
    Error: 'შეცდომა',
    ExampleForFair:
        'თამაშის ნომერი -  9088 თამაშის კოეფიციენტი - 1.06 გენერირების დრო - 12/03/2019_16:09 თამაშის უნიკალური ნომერი - Fvv+5TVHK2621A== შედეგად, თამაშის გასაღები იქნება: 9088_1.06_Boom_12/03/2019_16:09_Fvv+5TVHK2621A== ხოლო მისგან SHA256 ალგორითმით გენერირებული სანდოობის კოდი: 4b38fc175d17471ea757556444af951303d94198b16c7f666505dec6f251612d',
    Examples: 'მაგალითად:',
    FairGame: 'სამართლიანი თამაში',
    Fill: 'შევსება',
    FreeBet: 'ფრიბეთი',
    GameIsStopped: 'თამაში შეჩერებულია',
    GameKey: '2. რას ნიშნავს თამაშის გასაღები და სანდოობის კოდი?',
    GameOpenInNewWindow: 'თამაში გახსნილია სხვა ფანჯარაში',
    GameRules: 'თამაშის წესები',
    GameStart: 'თამაში იწყება',
    GameStarted: 'თამაში დაიწყო, წარმატებები',
    GameStatusIsNotRegistering: 'ფსონების მიღება დასრულებულია',
    GameWillResume: 'თამაშის განახლებამდე დარჩა',
    GotoHome: 'HOME',
    Hash: 'Hash კოდი',
    History: 'ისტორია',
    HowToCheckFair:
        "ეს მეთოდი საშუალებას გვაძლევს დავრწმუნდეთ, რომ თამაშის კოეფიციენტი (რიცხვი, რომელზეც 'ჩაიწვება' თამაში) განსაზღვრულია წინასწარ, სანამ მოთამაშეები დარეგისტრირდებიან. ნებისმიერ მომენტში წინასწარ განსაზღვრულია 5 თამაში. ქვემოთ მოყვანილია 5 გასული და 5 მომავალი თამაშის სანდოობის Hash კოდი.",
    HowToCheckGameKey:
        "თამაშების კოეფიციენტი (რიცხვი, რომელზეც 'ჩაიწვება' თამაში) განისაზღვრება წინასწარ, 5 თამაშით ადრე. თამაშის სერვერზე გენერირდება თამაშის გასაღები, რომელიც შედგება შემდეგი პარამეტრებისგან: [თამაშის ნომერი]_[თამაშის კოეფიციენტი]_[Boom]_[გენერირების დრო]_[თამაშის უნიკალური ნომერი]",
    HowToCheckHash:
        'თამაშის დამთავრების შემდეგ ქვემოთ გამოჩნდება თამაშის გასაღები რომელიც შეგიძლიათ დაშიფროთ ნებისმიერ ონლაინ SHA256 კალკულატორზე და შემდეგ შეადაროთ სანდოობის კოდს, რომელიც წინასწარ არის მოცემული მომდევნო 5 თამაშზე.',
    IncreaseBy: 'გაზარდე',
    Jackpot: 'ჯეკპოტი',
    JackpotRuleBust:
        'ჯეკპოტი ჩამოვარდება შემთხვევითობის პრინციპით და თანაბრად გაიყოფა რაუნდში მონაწილე იმ მოთამაშეებზე, რომლებმაც',
    JackpotRules: 'ჯეკპოტის წესები',
    JackpotRulesBalance: 'მოგება ავტომატურად აისახება მოთამაშის ბალანსზე.',
    JackpotRulesBetCondition: 'დადეს მინიმუმ 1₾ ფსონი',
    JackpotRulesCashoutCondition: 'არ დააქეშაუთეს 1.2-ზე ნაკლებ კოეფიციენტზე',
    Key: 'გასაღები',
    LanguageChangeAcception: 'ნამდვილად გსურთ ენის შეცვლა?',
    LanguageChangeWarning: 'ღილაკზე დაჭერით გაგეთიშებათ მიმდინარე თამაში',
    LastGames: 'ბოლო თამაშები',
    LastHand: 'წინა ხელი',
    LastJackpot: 'ბოლო ჯეკპოტი',
    LastTopSpins: 'ბოლო 24 საათის ტოპ კოეფიციენტები',
    LastTopWins: 'ბოლო 24 საათის ტოპ მოგებები',
    LeaveMessage: 'დაწერეთ კომენტარი',
    Login: 'შესვლა',
    LoginFailed: 'შესვლისას დაფიქსირდა შეცდომა',
    MainPage: 'მთავარი გვერდი',
    ManualBet: 'ძირითადი',
    MinFreebetCashout: 'მინ. კოეფიციენტი 1.5',
    Month: 'თვის',
    MultiplierCashout: 'კოეფ. მოგება',
    Music: 'მუსიკა',
    My: 'ჩემი',
    No: 'არა',
    NotEnoughMoney: 'არასაკმარისი თანხა',
    OnLoss: 'წაგებისას',
    OnWin: 'მოგებისას',
    OpenHere: 'გახსენი აქ',
    PlaceYourBet: 'მიმდინარეობს ფსონების მიღება',
    Player: 'მოთამაშე',
    Rating: 'რეიტინგი',
    Register: 'ფსონის დადება',
    Registration: 'მიმდინარეობს ფსონის მიღება',
    Reload: 'განახლება',
    Result: 'შედეგი',
    ResultsHash: 'შედეგის ჰეშ კოდი',
    ReturnToBaseBet: 'დაბრუნდი საწყის ფსონზე',
    ReturnToCash: 'Cash-ზე დაბრუნება',
    Round: 'რაუნდი',
    Rules1: 'ფსონის მინიმალური ოდენობა - 0.10₾; მაქსიმალური - 500₾.',
    Rules2: 'რაკეტის სიმაღლე მერყეობს 1-დან უსასრულობამდე.',
    Rules3: 'მოგება ითვლება: მიმდინარე სიმაღლის კოეფიციენტის და დადებული ფსონის ნამრავლით. მაქსიმალური მოგება ფსონზე შეადგენს 50 000₾-ს',
    Rules4: 'თუ აფეთქების მომენტში ფსონი არ იქნება დაქეშაუთებული, მაშინ ეს ფსონი წაგებულად ჩაითვლება.',
    Rules5: 'მოთამაშეს შეუძლია ერთდროულად ორი ფსონის დადება და მათი ცალ-ცალკე დაქეშაუთება.',
    Rules6: 'ჯეკპოტი ჩამოვარდება შემთხვევითობის პრინციპით და თანაბრად გაიყოფა რაუნდში ჯეკპოტი ჩამოვარდება შემთხვევითობის პრინციპით და თანაბრად გაიყოფა რაუნდში მონაწილე იმ მოთამაშეებზე, რომლებმაც:',
    Rules61: 'დადეს მინიმუმ 1₾ ფსონი',
    Rules62: 'არ დააქეშაუთეს 1.2-ზე ნაკლებ კოეფიციენტზე',
    Rules7: 'მოგება ავტომატურად აისახება მოთამაშის ბალანსზე.',
    Rules8: 'ერთი რაუნდის განმავლობაში მოთამაშეების მაქსიმალური ჯამური მოგება არის 100 000₾. იმ შემთხვევაში, როდესაც მოთამაშეთა მოგების ჯამი ერთ რაუნდში მიაღწევს 100 000₾-ს, სისტემა ავტომატურად დააქეშაუთებს ყველა ფსონს.',
    Save: 'შენახვა',
    ServerConnection: 'სერვერთან კავშირის აღდგენა',
    ServerError: 'სერვერთან დაკავშირებისას მოხდა შეცდომა',
    SessionHasExpired: 'სესიას ვადა გაუვიდა',
    SitesUpdate: 'მიმდინარეობს ტექნიკური სამუშაოები!',
    Sound: 'ხმა',
    Statistics: 'სტატისტიკა',
    StopIf: 'გაჩერდი თუ ფსონი >',
    Submit: 'გაგზავნა',
    SuccessfulRegistration: 'ფსონი მიღებულია',
    TechnicalWork: 'თამაშის უწყვეტი ფუნქციონირება მალე აღდგება.',
    TicketCashoutMustBe: 'ქეშაუთი უნდა იყოს მინუმუმ 1.5',
    Time: 'დრო',
    TopSpins: 'ტოპ კოეფ.',
    TopWins: 'მოგებები',
    UnknowError: 'სერვერის შეცდომა',
    Unregister: 'ფსონის გაუქმება',
    UnregisterFreeBet: 'ფრიბეთის გაუქმება',
    Unregistered: 'ფსონი გაუქმებულია',
    User: 'მომხმარებელი',
    UserName: 'სახელი',
    UserShort: 'მომ',
    WaitingForNextRound: 'ველოდებით შემდეგ რაუნდს',
    Win: 'მოგება',
    WinAmount: 'მოგება',
    WinMultiplier: 'მოგ. კოეფ.',
    Winners: 'გამარჯვებულები',
    WrongAmount: 'არასწორი თანხა',
    WrongConfiguration: 'არასწორი კონფიგურაცია',
    Year: 'წლის',
    Yes: 'კი',
    YouAreNotRegistered: 'თქვენ არ ხართ დარეგისტრირებული',
    EnterText: 'შეიყვანეთ ტექსტი',
    PlayerNotFound: 'მოთამაშე არ მოიძებნა',
    Jan: 'იან.',
    Feb: 'თებ.',
    Mar: 'მარ.',
    Apr: 'აპრ.',
    May: 'მაის.',
    June: 'ივნ.',
    July: 'ივლ.',
    Aug: 'აგვ.',
    Sept: 'სექ.',
    Oct: 'ოქტ.',
    Nov: 'ნოე.',
    Dec: 'დეკ.',
};
