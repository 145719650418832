import React, { FC } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import UserAvatar from '../../../../components/UserAvatar';
import { UserChatMessage } from '../../../../constants/interfaces/ChatMessage';
import Like from '../../../../components/Icons/Like';
import { useLikeChatMessageService } from '../../store/services';
import { formatUsername } from '../../../../helpers/functions/format-username';
import { useProfile } from '../../../profile/configs/store/state';
import { useParseDisplayMessage } from '../../hooks';

const MessageItemContainer = styled.div`
    font-family: ${(props) => props.theme.fonts.mediatorNarrow};
    margin-bottom: 16px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
`;

const LikeMessage = styled.div`
    display: flex;
    align-items: center;
`;

const IconWrapper = styled.div<{ liked?: boolean }>`
    height: 11.26px;
    width: 10px;
    display: flex;
    color: ${(props) =>
        props.liked
            ? props.theme.colors.paleRed
            : rgba(props.theme.colors.white, 0.54)};
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
`;

const Counter = styled.div`
    font-size: 12px;
    margin-right: 4px;
    width: 5px;
    color: ${(props) => rgba(props.theme.colors.white, 0.54)};
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
`;

const Avatar = styled.div`
    height: 24px;
    width: 24px;
    border-radius: 100%;
`;

const Name = styled.div`
    margin-left: 8px;
    font-size: 14px;
    opacity: 0.5;
    font-weight: bold;
    color: ${(props) => rgba(props.theme.colors.white, 0.84)};
`;

const Text = styled.div`
    color: ${(props) => rgba(props.theme.colors.white, 0.84)};
    font-size: 12px;
    font-weight: 300;
    word-break: break-word;
    & > .chat-emoji {
        height: 25px;
        width: 25px;
    }
    & > .chat-giff {
        height: inherit;
        max-width: 275px;
        max-height: 250px;
        padding: 2px;
        border-radius: 4px;
        border: 1px solid #4b4b61;
    }
`;

const MessageItem: FC<{ message: UserChatMessage }> = ({ message }) => {
    const likeChatMessage = useLikeChatMessageService();
    const profileId = useProfile().Player?.I;
    const parseChatMessage = useParseDisplayMessage(true);

    const handleMessageLike = () => {
        if (!message.MessageId) return;
        likeChatMessage(message.MessageId);
    };

    return (
        <MessageItemContainer>
            <Header>
                <UserInfo>
                    <Avatar>
                        <UserAvatar size={24} avatarId={message.Player.A} />
                    </Avatar>
                    <Name>
                        {profileId === message.Player.I
                            ? message.Player.N
                            : formatUsername(message.Player.N)}
                    </Name>
                </UserInfo>
                <LikeMessage>
                    <Counter>
                        {message.LikeCount > 0 && message.LikeCount}
                    </Counter>
                    <IconWrapper
                        onClick={handleMessageLike}
                        liked={message.Liked}
                    >
                        <Like />
                    </IconWrapper>
                </LikeMessage>
            </Header>
            <Text
                dangerouslySetInnerHTML={{
                    __html: parseChatMessage(message.Text),
                }}
            />
        </MessageItemContainer>
    );
};

export default MessageItem;
