import React, { FC } from 'react';
import { ListStatus, ListUser } from '../../../../constants/interfaces/List';
import { TableRow } from '../../../../components/Table/TableRow';
import {
    SuccessFirstTableCell,
    SuccessFourthTableCell,
    SuccessSecondTableCell,
    SuccessThirdTableCell,
} from '../../components/BetsTable/TableSuccessCell';
import TableAvatar from '../../components/TableAvatar';
import {
    FailureFirstTableCell,
    FailureFourthTableCell,
    FailureSecondTableCell,
    FailureThirdTableCell,
} from '../../components/BetsTable/TableFailureCell';
import {
    NeutralFirstTableCell,
    NeutralFourthTableCell,
    NeutralSecondTableCell,
    NeutralThirdTableCell,
} from '../../components/BetsTable/NeutralTableCell';
import styled from 'styled-components';
import { roundNumber } from '../../../../helpers/functions/round-number';

const AvatarContainer = styled.span`
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    white-space: nowrap;
`;

const TableEllipsisWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
`;

const TableUsernameContainer = styled.div`
    right: 0;
    position: absolute;
    left: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const RenderTableRows: FC<{ items: ListUser[] }> = ({ items }): any => {
    return (
        items &&
        items.map((player, index) => {
            switch (player.status) {
                case ListStatus.WON: {
                    return (
                        <TableRow key={JSON.stringify(player + `${index}`)}>
                            <SuccessFirstTableCell>
                                <TableEllipsisWrapper>
                                    <AvatarContainer>
                                        <TableAvatar avatarId={player.avatar} />
                                    </AvatarContainer>
                                    <TableUsernameContainer>
                                        {player.username}
                                    </TableUsernameContainer>
                                </TableEllipsisWrapper>
                            </SuccessFirstTableCell>
                            <SuccessSecondTableCell>
                                {roundNumber(player.bet)} ₾
                            </SuccessSecondTableCell>
                            <SuccessThirdTableCell>
                                {player.odds ? player.odds : '-'}X
                            </SuccessThirdTableCell>
                            <SuccessFourthTableCell>
                                {player.profit
                                    ? `${roundNumber(player.profit)} ₾`
                                    : '-'}
                            </SuccessFourthTableCell>
                        </TableRow>
                    );
                }
                case ListStatus.LOST: {
                    return (
                        <TableRow key={JSON.stringify(player + `${index}`)}>
                            <FailureFirstTableCell>
                                <TableEllipsisWrapper>
                                    <AvatarContainer>
                                        <TableAvatar avatarId={player.avatar} />
                                    </AvatarContainer>
                                    <TableUsernameContainer>
                                        {player.username}
                                    </TableUsernameContainer>
                                </TableEllipsisWrapper>
                            </FailureFirstTableCell>
                            <FailureSecondTableCell>
                                {roundNumber(player.bet)} ₾
                            </FailureSecondTableCell>
                            <FailureThirdTableCell>-</FailureThirdTableCell>
                            <FailureFourthTableCell>-</FailureFourthTableCell>
                        </TableRow>
                    );
                }
                default: {
                    return (
                        <TableRow key={JSON.stringify(player + `${index}`)}>
                            <NeutralFirstTableCell>
                                <TableEllipsisWrapper>
                                    <AvatarContainer>
                                        <TableAvatar avatarId={player.avatar} />
                                    </AvatarContainer>
                                    <TableUsernameContainer>
                                        {player.username}
                                    </TableUsernameContainer>
                                </TableEllipsisWrapper>
                            </NeutralFirstTableCell>
                            <NeutralSecondTableCell>
                                {roundNumber(player.bet)} ₾
                            </NeutralSecondTableCell>
                            <NeutralThirdTableCell>-</NeutralThirdTableCell>
                            <NeutralFourthTableCell>
                                {player.profit ? `${player.profit} ₾` : '-'}
                            </NeutralFourthTableCell>
                        </TableRow>
                    );
                }
            }
        })
    );
};

export default RenderTableRows;
