import React, { FC } from 'react';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import useTranslate from '../../../config/language';
import { KEYWORDS } from '../../../config/language/keywords';

const Container = styled.div`
    background: ${props => props.theme.colors.charcoalGreyTwo};
    font-family: ${props => props.theme.fonts.mediatorNarrowCaps};
    border-radius: 6px;
    box-shadow: none;
    width: 600px;
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%;
`;

const Title = styled.div`
    color: ${props => props.theme.colors.white};
    opacity: 0.8;
    margin: 0 0 24px 0;
    font-size: 24px;
    font-family: ${props => props.theme.fonts.mediatorNarrowCaps};
    font-weight: bold;
    text-align: center;
`;

const Paragraph = styled.p`
    line-height: 18px;
    color: rgb(192, 192, 192);
    font-size: 14px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    font-weight: bold;
    text-align: left;
    margin-bottom: 16px;
    padding-left: 24px;
    &::before {
        content: '';
        height: 0;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #e74e0f;
    }
`;

const GameRulesModal: FC<{
    gameId?: number;
    hashCode?: string;
    hashKey?: string;
    bustedAt?: number;
    closeModal(): void;
}> = ({ closeModal }) => {
    const translate = useTranslate();

    return (
        <Modal closeModal={closeModal}>
            <Container>
                <Title>{translate(KEYWORDS.GameRules)}</Title>
                <Paragraph>{translate(KEYWORDS.Rules1)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules2)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules3)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules4)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules5)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules7)}</Paragraph>
                <Paragraph>{translate(KEYWORDS.Rules8)}</Paragraph>
            </Container>
        </Modal>
    );
};

export default GameRulesModal;
