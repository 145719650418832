import React, { FC } from 'react';
import Button from '../../../../../components/Button/Button';
import styled from 'styled-components';
import useTranslate from '../../../../../config/language';
import { KEYWORDS } from '../../../../../config/language/keywords';
import { variables } from '../../../../../constants/variables';
import { roundNumber } from '../../../../../helpers/functions/round-number';

const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const StyledButton = styled(Button)`
    @media (max-height: ${550}px), (max-width: ${600}px) {
        font-size: 13px;
    }
`;

const CashOutPanel: FC<{
    amount: number;
    handleCashOut(): void;
    disabled?: boolean;
}> = ({ amount, handleCashOut, disabled }) => {
    const translate = useTranslate();

    return (
        <StyledContainer>
            <StyledButton
                text={`${translate(KEYWORDS.Cashout)} (${roundNumber(
                    amount
                )}₾)`}
                background={variables.colors.frogGreen}
                onClick={handleCashOut}
                disabled={disabled}
            />
        </StyledContainer>
    );
};

export default CashOutPanel;
