import React, { FC } from 'react';
import styled from 'styled-components';
import {
    Bubble0,
    Bubble1,
    Bubble10,
    Bubble11,
    Bubble12,
    Bubble2,
    Bubble3,
    Bubble4,
    Bubble5,
    Bubble6,
    Bubble7,
    Bubble8,
    Bubble9,
} from './Bubble';

const FlameContainer = styled.div`
    width: 166px;
    height: 166px;
    background: transparent;
    clip-path: circle(81px at center);
    position: relative;
    margin: auto;
    margin-top: -174px;
`;

const Flame: FC = () => {
    return (
        <FlameContainer>
            <Bubble0 />
            <Bubble1 />
            <Bubble2 />
            <Bubble3 />
            <Bubble4 />
            <Bubble5 />
            <Bubble6 />
            <Bubble7 />
            <Bubble8 />
            <Bubble9 />
            <Bubble10 />
            <Bubble11 />
            <Bubble12 />
        </FlameContainer>
    );
};

export default Flame;
