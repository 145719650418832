import React, { FC } from 'react';
import Button from '../../../../../components/Button/Button';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useTranslate from '../../../../../config/language';
import { KEYWORDS } from '../../../../../config/language/keywords';
import { roundNumber } from '../../../../../helpers/functions/round-number';

const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const BetButtonContainer = styled(motion.div)`
    display: flex;
    flex: 48;
    margin-right: 6px;
`;

const StyledButton = styled(Button)`
    background: ${(props) => props.theme.colors.redPink};
    white-space: nowrap;
`;

const UnregisterPanel: FC<{
    amount: number;
    handleUnregister(): void;
    disabled?: boolean;
}> = ({ amount, handleUnregister, disabled }) => {
    const translate = useTranslate();

    return (
        <StyledContainer>
            <BetButtonContainer
                initial={{ scaleX: 0.5 }}
                animate={{ scaleX: 1 }}
                transition={{ duration: 0.1 }}
            >
                <StyledButton
                    text={`${translate(KEYWORDS.Unregister)} (${roundNumber(
                        amount
                    )}₾)`}
                    onClick={handleUnregister}
                    disabled={disabled}
                />
            </BetButtonContainer>
        </StyledContainer>
    );
};

export default UnregisterPanel;
