import React, { FC, memo } from 'react';
import styled from 'styled-components';
import Multiplier from '../../../components/Multiplier/Multiplier';
import { motion } from 'framer-motion';
import { getMultiplierRGBColor } from '../../betColors';
import { roundNumber } from '../../../helpers/functions/round-number';

const MultiplierContainer = styled(motion.div)`
    position: absolute;
    left: 100%;
    top: 50px;

    @media (max-width: ${750}px) {
        left: 85%;
    }

    @media (max-width: ${550}px) {
        left: 85%;
        top: 20px;
    }

    @media (max-width: ${450}px) {
        left: 50%;
        top: 30px;
    }
`;

const StyledMultiplier = styled(Multiplier)`
    min-width: 200px;
    height: 112px;
    transition: all 0.2s;
    font-size: 45px;

    @media (max-width: ${1500}px) {
        min-width: 175px;
    }

    @media (max-width: ${1000}px) {
        min-width: 125px;
        height: 88px;
        font-size: 28px;
    }

    @media (max-width: ${630}px) {
        min-width: 100px;
        height: 88px;
        font-size: 24px;
    }

    @media (max-width: ${580}px) {
        min-width: 80px;
        height: 82px;
        font-size: 24px;
    }

    @media (max-height: ${700}px) {
        height: 90px;
        font-size: 24px;
    }

    @media (max-height: ${500}px) {
        height: 88px;
        font-size: 24px;
    }
`;

const GameMultiplier: FC<{ multiplier?: number | null; animate?: boolean }> = ({
    multiplier,
    animate,
}) => {
    if (!multiplier) return null;

    return (
        <MultiplierContainer
            initial={animate ? { x: 200 } : undefined}
            animate={animate ? { x: 0 } : undefined}
            transition={{ duration: 0.3 }}
        >
            <StyledMultiplier
                text={`${roundNumber(multiplier)}x`}
                color={getMultiplierRGBColor(multiplier)}
            />
        </MultiplierContainer>
    );
};

export default memo(GameMultiplier);
