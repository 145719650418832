import styled from 'styled-components';
import {
    NeutralFirstTableCell,
    NeutralFourthTableCell,
    NeutralSecondTableCell,
    NeutralThirdTableCell,
} from './NeutralTableCell';

export const SuccessFirstTableCell = styled(NeutralFirstTableCell)`
    background-image: linear-gradient(to right, #60d62b66, #59d53059);
`;

export const SuccessSecondTableCell = styled(NeutralSecondTableCell)`
    background-image: linear-gradient(
        to right,
        rgba(87, 213, 47, 0.35),
        rgba(74, 210, 53, 0.25)
    );
`;

export const SuccessThirdTableCell = styled(NeutralThirdTableCell)`
    background-image: linear-gradient(
        to right,
        rgba(72, 209, 52, 0.25),
        rgba(59, 207, 58, 0.15)
    );
`;

export const SuccessFourthTableCell = styled(NeutralFourthTableCell)`
    background-image: linear-gradient(
        to right,
        rgba(59, 207, 59, 0.15),
        rgba(45, 205, 64, 0)
    );
`;
