import styled from 'styled-components';
import {
    NeutralFirstTableCell,
    NeutralFourthTableCell,
    NeutralSecondTableCell,
    NeutralThirdTableCell,
} from './NeutralTableCell';

export const FailureFirstTableCell = styled(NeutralFirstTableCell)`
    background-image: linear-gradient(
        to right,
        rgba(240, 47, 76, 0.58),
        rgba(240, 47, 76, 0.5)
    );
`;

export const FailureSecondTableCell = styled(NeutralSecondTableCell)`
    background-image: linear-gradient(
        to right,
        rgba(240, 47, 76, 0.5),
        rgba(240, 47, 76, 0.35)
    );
`;

export const FailureThirdTableCell = styled(NeutralThirdTableCell)`
    background-image: linear-gradient(
        to right,
        rgba(240, 47, 76, 0.35),
        hsla(351, 87%, 56%, 0.15)
    );
`;

export const FailureFourthTableCell = styled(NeutralFourthTableCell)`
    background-image: linear-gradient(
        to right,
        rgba(240, 47, 76, 0.15),
        hsla(351, 87%, 56%, 0)
    );
`;
