export enum GAME_METHODS {
    GAME_CREATED = 'G1',
    GAME_STARTED = 'GS',
    GAME_CRUSHED = 'GC',
    REGISTRATION_FINISHED = 'RF',
    CURRENT_MULTIPLIER = 'CM',
    PLAYER_REGISTERED = 'PR',
    PLAYER_UNREGISTERED = 'PU',
    PLAYER_CASHED_OUT = 'PCO',
    MESSAGE = 'M',
    MESSAGE_LIKED = 'ML',
    SYSTEM_MESSAGE = 'BW',
    CURRENT_STATE = 'CurrentState',
    REGISTER_RESPONSE = 'PlayerRegisterResultReceived',
    UNREGISTER_RESPONSE = 'PlayerUnRegisterResultReceived',
    GAME_DISCONNECT = 'Disconnect',
    PLAYER_BALANCE = 'PB',
}

export const gameMethodNames = {
    [GAME_METHODS.GAME_CREATED as string]: 'GameCreated',
    [GAME_METHODS.GAME_STARTED as string]: 'GameStarted',
    [GAME_METHODS.GAME_CRUSHED as string]: 'GameCrashed',
    [GAME_METHODS.REGISTRATION_FINISHED as string]: 'RegistrationFinished',
    [GAME_METHODS.CURRENT_MULTIPLIER as string]: 'CurrentMultiplier',
    [GAME_METHODS.PLAYER_REGISTERED as string]: 'PlayerRegistered',
    [GAME_METHODS.PLAYER_UNREGISTERED as string]: 'PlayerUnregistered',
    [GAME_METHODS.PLAYER_CASHED_OUT as string]: 'PlayerCashedOut',
    [GAME_METHODS.MESSAGE as string]: 'Message',
};

export function gameMethodName(status?: string): string {
    return (status && gameMethodNames[status]) || status || '';
}
