import React, { createContext, FC } from 'react';
import { useGameStatusState } from '../../config/store/state/app.state';
import { GAME_STATE, GameStatus } from '../../constants/interfaces/Game';
import styled from 'styled-components';
import GameMultiplier from './components/GameMultiplier';
import useWindowDimensions from '../../helpers/hooks/windowDimentions';
import { calculateGameBoardDimensions } from './tools';
import Ruler from '../../components/Ruller/Ruler';
import RenderGame from './components/RenderGame';

const StyledGameBoardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
`;

const GameBorder = styled.div<{ width: number; height: number }>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding-top: 6px;
`;

export const GameContext = createContext({ gameWidth: 300, gameHeight: 400 });

const GameBoard: FC<{ game: GameStatus }> = ({ game }) => {
    const { width, height } = useWindowDimensions();
    const gameDimensions = calculateGameBoardDimensions(width, height);

    return (
        <StyledGameBoardContainer>
            <GameBorder
                width={gameDimensions.width}
                height={gameDimensions.height}
            >
                <GameContext.Provider
                    value={{
                        gameWidth: gameDimensions.width,
                        gameHeight: gameDimensions.height,
                    }}
                >
                    <RenderGame gameState={game.state} />
                </GameContext.Provider>
                <GameMultiplier
                    multiplier={game.multiplier}
                    animate={game.state === GAME_STATE.LAUNCHING}
                />
            </GameBorder>
            {game.state === GAME_STATE.RUNNING && (
                <Ruler multiplier={game.multiplier} />
            )}
        </StyledGameBoardContainer>
    );
};

const GameBoardContainer = () => {
    const gameState = useGameStatusState();
    if (!gameState) return <div />;
    return <GameBoard game={gameState} />;
};
export default GameBoardContainer;
