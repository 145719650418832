import styled from 'styled-components';
import { TableCell } from '../../../../components/Table/TableCell';

export const NeutralFirstTableCell = styled(TableCell)`
    width: 30%;
    padding-left: 8px;
    letter-spacing: 0;
    text-align: left;
`;

export const NeutralSecondTableCell = styled(TableCell)`
    width: 20%;
`;

export const NeutralThirdTableCell = styled(TableCell)`
    width: 20%;
    color: ${(props) => props.theme.colors.frogGreen};
`;

export const NeutralFourthTableCell = styled(TableCell)`
    width: 30%;
    padding-right: 10px;
`;
