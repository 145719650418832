import {
    ACTIONS,
    boardAddedAction,
    boardRemovedAction,
    cashOutAction,
    currentMultiplierAction,
    gameCreatedAction,
    gameCrushedAction,
    gameErrorAction,
    gameStartedAction,
    initializeGameSettingsAction,
    initializeGameStateAction,
    loadGameAction,
    pendingAction,
    playerBalanceChangeAction,
    playerCashedOutAction,
    playerRegisteredAction,
    PlayerRegisterErrorStatuses,
    playerUnregisteredAction,
    registerAction,
    registerFailAction,
    registerSuccessAction,
    saveSettingsAction,
    setLaunchingAction,
    toggleEnableFreeBetsAction,
    unregisterAction,
    unregisterActionFail,
    unregisterActionSuccess,
    unregisterLocalAction,
} from '../actions/app.actions';
import { Reducer } from 'react';
import {
    GAME_STATE,
    GAME_STATE_BY_INDEX,
} from '../../../constants/interfaces/Game';
import { ListStatus, ListUser } from '../../../constants/interfaces/List';
import { GameState, RegisterState } from '../state/app.state';
import { formatUsername } from '../../../helpers/functions/format-username';
import {
    initializeMyBetsAction,
    MY_BETS_ACTIONS,
} from '../../../features/topBoards/myBets/configs/store/actions';
import {
    BETS_ACTIONS,
    initializeCurrentBetsAction,
    initializeLastBetsAction,
} from '../../../features/topBoards/bets/configs/store/actions';
import {
    calculateCashedOutAmount,
    mapCurrentBetsValues,
    mapLastBetsValues,
} from '../../../features/topBoards/bets/configs/helpers';
import {
    initializeTopMultiplierCashOutsAction,
    initializeTopOddsAction,
    initializeTopWinningsAction,
    TOP_BETS_ACTIONS,
} from '../../../features/topBoards/topBets/configs/store/actions';
import {
    GAMES_HISTORY_ACTIONS,
    initializeGamesHistoryAction,
} from '../../../features/oddsList/configs/store/actions';
import { GameHistory } from '../../../constants/interfaces/GameHistory';
import {
    initializeNextGamesAction,
    NEXT_GAMES_ACTIONS,
} from '../../../features/nextGames/configs/store/actions';
import {
    initializeProfileAction,
    PROFILE_ACTIONS,
    toggleConfigAnimationAction,
    toggleConfigBackgroundMusicAction,
    toggleConfigVoiceAction,
} from '../../../features/profile/configs/store/actions';
import { roundNumber } from '../../../helpers/functions/round-number';
import { ErrorListNames } from '../../../components/Modals/ErrorList';

type GameActions =
    | ReturnType<typeof gameCreatedAction>
    | ReturnType<typeof gameStartedAction>
    | ReturnType<typeof gameCrushedAction>
    | ReturnType<typeof setLaunchingAction>
    | ReturnType<typeof currentMultiplierAction>
    | ReturnType<typeof playerRegisteredAction>
    | ReturnType<typeof playerUnregisteredAction>
    | ReturnType<typeof playerCashedOutAction>
    | ReturnType<typeof registerAction>
    | ReturnType<typeof registerSuccessAction>
    | ReturnType<typeof registerFailAction>
    | ReturnType<typeof pendingAction>
    | ReturnType<typeof unregisterAction>
    | ReturnType<typeof unregisterLocalAction>
    | ReturnType<typeof unregisterActionSuccess>
    | ReturnType<typeof unregisterActionFail>
    | ReturnType<typeof cashOutAction>
    | ReturnType<typeof boardAddedAction>
    | ReturnType<typeof boardRemovedAction>
    | ReturnType<typeof playerBalanceChangeAction>
    | ReturnType<typeof initializeCurrentBetsAction>
    | ReturnType<typeof initializeLastBetsAction>
    | ReturnType<typeof initializeMyBetsAction>
    | ReturnType<typeof initializeTopOddsAction>
    | ReturnType<typeof initializeTopWinningsAction>
    | ReturnType<typeof initializeTopMultiplierCashOutsAction>
    | ReturnType<typeof initializeGamesHistoryAction>
    | ReturnType<typeof initializeNextGamesAction>
    | ReturnType<typeof initializeProfileAction>
    | ReturnType<typeof toggleConfigBackgroundMusicAction>
    | ReturnType<typeof toggleConfigAnimationAction>
    | ReturnType<typeof toggleConfigVoiceAction>
    | ReturnType<typeof toggleEnableFreeBetsAction>
    | ReturnType<typeof loadGameAction>
    | ReturnType<typeof initializeGameStateAction>
    | ReturnType<typeof saveSettingsAction>
    | ReturnType<typeof initializeGameSettingsAction>
    | ReturnType<typeof gameErrorAction>;

const initialAppState: GameState = {
    currentBets: [],
    lastBets: [],
    gameHistory: [],
    top: {
        odds: undefined,
        winnings: undefined,
        multiCashOut: undefined,
    },
    configs: {
        animate: true,
        backgroundMusic: false,
        voiceEffects: false,
    },
    status: { state: GAME_STATE.INITIAL, duration: 6 },
    boards: [
        { PanelIndex: 0, registerState: RegisterState.UNREGISTERED },
        { PanelIndex: 1, registerState: RegisterState.UNREGISTERED },
        { PanelIndex: 2, registerState: RegisterState.UNREGISTERED },
        { PanelIndex: 3, registerState: RegisterState.UNREGISTERED },
        { PanelIndex: 4, registerState: RegisterState.UNREGISTERED },
    ],
    profile: {
        Player: {
            I: 0,
            N: '',
            A: '',
        },
        B: {
            WalletBalance: 0,
            FreeSpinBalance: [],
        },
    },
    freeBetsEnabled: false,
    loading: true,
    errors: {
        isError: false,
        type: ErrorListNames.Disconnect,
    },
};

export const gameReducers: Reducer<GameState, GameActions> = (
    state: GameState = initialAppState,
    action: GameActions
): GameState => {
    switch (action.type) {
        case ACTIONS.GAME_CREATED: {
            const { GameId, NextGames } = action.payload;

            const updatedGameHistory = [...state.gameHistory];
            if (state.lastGame) {
                updatedGameHistory.unshift(state.lastGame);
                if (updatedGameHistory.length > 25) {
                    updatedGameHistory.pop();
                }
            }

            return {
                ...state,
                status: {
                    ...state.status,
                    id: GameId,
                    state: GAME_STATE.COUNTDOWN,
                    multiplier: null,
                },
                lastBets:
                    state.currentBets.length > 0
                        ? state.currentBets
                        : state.lastBets,
                currentBets: [],
                nextGames: NextGames,
                gameHistory: updatedGameHistory,
            };
        }
        case ACTIONS.GAME_STARTED: {
            const updatedBoards = [...state.boards].map((board) => {
                if (board.registerState !== RegisterState.REGISTERED) {
                    return {
                        ...board,
                        registerState: RegisterState.UNREGISTERED,
                        wonLastGame: false,
                    };
                } else {
                    return { ...board, wonLastGame: false };
                }
            });

            return {
                ...state,
                status: {
                    ...state.status,
                    state: GAME_STATE.RUNNING,
                    id: action.payload.GameId,
                    multiplier: action.payload.Multiplier,
                },
                boards: updatedBoards,
            };
        }
        case ACTIONS.GAME_CRUSHED: {
            const { Multiplier, HashKey, GameId } = action.payload;
            const updatedCurrentBets: ListUser[] = state.currentBets.map(
                (user) => {
                    if (user.status !== ListStatus.WON) {
                        return {
                            ...user,
                            status: ListStatus.LOST,
                        };
                    } else {
                        return user;
                    }
                }
            );

            let CrashHash = '';
            const filteredNextGame = state.nextGames?.filter(
                (game) => game.GameID === GameId
            );

            if (filteredNextGame && filteredNextGame.length > 0) {
                CrashHash = filteredNextGame[0].Hash;
            } else if (state.currentGame?.GameID === GameId) {
                CrashHash = state.currentGame.Hash;
            }

            const GameHistoryItem: GameHistory = {
                ID: GameId,
                CrashHash: CrashHash,
                CrashSource: HashKey,
                BustedAt: Multiplier,
            };

            const updatedBoards = state.boards.map((board) => {
                if (board.registerState === RegisterState.REGISTERED) {
                    return {
                        ...board,
                        registerState: RegisterState.UNREGISTERED,
                    };
                } else {
                    return board;
                }
            });

            return {
                ...state,
                status: {
                    ...state.status,
                    state: GAME_STATE.CRUSHED,
                    multiplier: action.payload.Multiplier,
                    hashKey: action.payload.HashKey,
                },
                lastGame: GameHistoryItem,
                currentBets: updatedCurrentBets,
                currentGame: state?.nextGames![0] || undefined,
                boards: updatedBoards,
            };
        }
        case ACTIONS.SET_LAUNCHING: {
            return {
                ...state,
                status: {
                    ...state.status,
                    state: GAME_STATE.LAUNCHING,
                    multiplier: 1,
                },
            };
        }
        case ACTIONS.CURRENT_MULTIPLIER: {
            return {
                ...state,
                status: {
                    ...state.status,
                    state: GAME_STATE.RUNNING,
                    multiplier: action.payload.Multiplier,
                },
            };
        }
        case ACTIONS.PLAYER_REGISTERED: {
            const {
                Player,
                BuyIn,
                PanelIndex,
                YouRegisteredOnGame,
                ErrorCode,
            } = action.payload;
            const updatedCurrentBets: ListUser[] = state.currentBets;
            const isMe = Player.I === state.profile.MyID;
            const registeredPlayer: ListUser = {
                id: Player.I,
                avatar: Player.A,
                username: isMe ? Player.N : formatUsername(Player.N),
                bet: BuyIn,
                status: ListStatus.DEFAULT,
                PanelIndex: PanelIndex,
            };
            if (
                YouRegisteredOnGame &&
                ErrorCode === PlayerRegisterErrorStatuses.Success
            ) {
                const { PlayerBalance, CurrentPlayer } = YouRegisteredOnGame;
                updatedCurrentBets.unshift(registeredPlayer);
                const updatedBoards = [...state.boards].map((board) => {
                    if (board.PanelIndex === CurrentPlayer.PanelIndex) {
                        return {
                            ...board,
                            registerState: RegisterState.REGISTERED,
                        };
                    } else return board;
                });
                const updatedMyBets = state.myBets ? [...state.myBets] : [];

                updatedMyBets.unshift({
                    ID: action.payload.Player.I,
                    BuyIn: action.payload.BuyIn,
                    CashOut: 0,
                    Timestamp: new Date().toISOString(),
                    GameID: action.payload.GameId,
                    PanelIndex: action.payload.PanelIndex,
                });

                return {
                    ...state,
                    currentBets: JSON.parse(JSON.stringify(updatedCurrentBets)),
                    profile: {
                        ...state.profile,
                        B: PlayerBalance,
                    },
                    boards: updatedBoards,
                    myBets: updatedMyBets,
                };
            } else if (
                YouRegisteredOnGame &&
                ErrorCode !== PlayerRegisterErrorStatuses.Success
            ) {
                const { CurrentPlayer } = YouRegisteredOnGame;
                const updatedBoards = [...state.boards].map((board) => {
                    if (board.PanelIndex === CurrentPlayer.PanelIndex) {
                        return {
                            ...board,
                            registerState: RegisterState.UNREGISTERED,
                        };
                    } else return board;
                });
                return {
                    ...state,
                    currentBets: JSON.parse(JSON.stringify(updatedCurrentBets)),
                    boards: updatedBoards,
                };
            } else {
                updatedCurrentBets.push(registeredPlayer);
                return {
                    ...state,
                    currentBets: JSON.parse(JSON.stringify(updatedCurrentBets)),
                };
            }
        }
        case ACTIONS.PLAYER_UNREGISTERED: {
            const { Player, PanelIndex, YouUnRegisteredOnGame } =
                action.payload;

            const updatedCurrentBets = state.currentBets.filter(
                (user) =>
                    user.id !== Player.I ||
                    (user.id === Player.I && user.PanelIndex !== PanelIndex)
            );

            if (YouUnRegisteredOnGame) {
                const { CurrentPlayer } = YouUnRegisteredOnGame;
                const updatedBoards = [...state.boards].map((board) => {
                    if (board.PanelIndex === CurrentPlayer.PanelIndex) {
                        return {
                            ...board,
                            registerState: RegisterState.UNREGISTERED,
                        };
                    } else return board;
                });

                let updatedMyBets = state.myBets ? [...state.myBets] : [];
                updatedMyBets = updatedMyBets.filter(
                    (myBet) =>
                        myBet.GameID !== YouUnRegisteredOnGame.GameId ||
                        (myBet.GameID === YouUnRegisteredOnGame.GameId &&
                            myBet.PanelIndex !== CurrentPlayer.PanelIndex)
                );

                return {
                    ...state,
                    currentBets: updatedCurrentBets,
                    profile: {
                        ...state.profile,
                        B:
                            YouUnRegisteredOnGame.PlayerBalance ||
                            state.profile.B,
                    },
                    boards: updatedBoards,
                    myBets: updatedMyBets,
                };
            } else {
                return {
                    ...state,
                    currentBets: updatedCurrentBets,
                };
            }
        }
        case ACTIONS.PLAYER_CASHED_OUT: {
            const { Multiplier, PlayerId, YouCashedOut, PanelIndex } =
                action.payload;

            const updatedCurrentBets: ListUser[] = state.currentBets.map(
                (user) => {
                    if (
                        user.id === PlayerId &&
                        user.PanelIndex === PanelIndex
                    ) {
                        const profit = Multiplier * user.bet;
                        return {
                            ...user,
                            status: ListStatus.WON,
                            odds: Multiplier,
                            profit: roundNumber(profit),
                        };
                    } else {
                        return user;
                    }
                }
            );

            if (YouCashedOut) {
                const { CashedOutPlayer } = YouCashedOut;
                const updatedBoards = [...state.boards].map((board) => {
                    if (board.PanelIndex === CashedOutPlayer.PanelIndex) {
                        return {
                            ...board,
                            registerState: RegisterState.UNREGISTERED,
                            wonLastGame: true,
                        };
                    } else return board;
                });

                let updatedMyBets = state.myBets ? [...state.myBets] : [];
                updatedMyBets = updatedMyBets.map((myBet) => {
                    if (
                        myBet.PanelIndex === CashedOutPlayer.PanelIndex &&
                        myBet.GameID === YouCashedOut.GameId
                    ) {
                        return {
                            ...myBet,
                            CashOut: calculateCashedOutAmount(CashedOutPlayer),
                        };
                    } else {
                        return myBet;
                    }
                });

                return {
                    ...state,
                    currentBets: updatedCurrentBets,
                    profile: {
                        ...state.profile,
                        B: YouCashedOut.PlayerBalance || state.profile.B,
                    },
                    boards: updatedBoards,
                    myBets: updatedMyBets,
                };
            } else {
                return {
                    ...state,
                    currentBets: updatedCurrentBets,
                };
            }
        }
        case ACTIONS.BOARD_ADDED: {
            const updatedBoards = [...state.boards];

            const exists = updatedBoards.filter(
                (board) => board.PanelIndex === action.payload.PanelIndex
            )[0];

            if (!exists) {
                updatedBoards.push(action.payload);
            }

            return {
                ...state,
                boards: updatedBoards,
            };
        }
        case ACTIONS.BOARD_REMOVED: {
            const updatedBoards = [...state.boards].filter(
                (board) => board.PanelIndex !== action.payload.PanelIndex
            );
            return {
                ...state,
                boards: updatedBoards,
            };
        }
        case ACTIONS.BALANCE_CHANGE: {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    B: action.payload.PlayerBalance,
                },
            };
        }
        case ACTIONS.PENDING: {
            let updatedBoards = [...state.boards].map((board) => {
                if (board.PanelIndex === action.payload.PanelIndex) {
                    return {
                        registerState: RegisterState.PENDING,
                        ...action.payload,
                        wonLastGame: board.wonLastGame,
                    };
                } else {
                    return board;
                }
            });
            return {
                ...state,
                boards: updatedBoards,
            };
        }
        case ACTIONS.REGISTER: {
            let updatedBoards = [...state.boards].map((board) => {
                if (board.PanelIndex === action.payload.PanelIndex) {
                    return {
                        registerState: RegisterState.REGISTER_INITIATED,
                        ...action.payload,
                        wonLastGame: board.wonLastGame,
                    };
                } else {
                    return board;
                }
            });
            return {
                ...state,
                boards: updatedBoards,
            };
        }
        case ACTIONS.UNREGISTER:
        case ACTIONS.UNREGISTER_LOCAL: {
            const updatedBoards = [...state.boards].map((board) => {
                if (board.PanelIndex === action.payload.PanelIndex) {
                    return {
                        PanelIndex: board.PanelIndex,
                        registerState:
                            board.registerState === RegisterState.PENDING ||
                            board.registerState ===
                                RegisterState.REGISTER_INITIATED
                                ? RegisterState.UNREGISTERED
                                : RegisterState.UNREGISTER_INITIATED,
                        wonLastGame: board.wonLastGame,
                    };
                } else {
                    return board;
                }
            });
            return {
                ...state,
                boards: updatedBoards,
            };
        }
        case ACTIONS.CASH_OUT: {
            const updatedBoards = [...state.boards].map((board) => {
                if (board.PanelIndex === action.payload.PanelIndex) {
                    return {
                        PanelIndex: board.PanelIndex,
                        registerState: RegisterState.CASH_OUT_INITIATED,
                    };
                } else {
                    return board;
                }
            });
            return {
                ...state,
                boards: updatedBoards,
            };
        }
        case BETS_ACTIONS.INITIALIZE_CURRENT_BETS: {
            const { currentBets, currentBetsCashedOut } = action;
            return {
                ...state,
                currentBets: mapCurrentBetsValues(
                    currentBets,
                    currentBetsCashedOut
                ),
            };
        }
        case BETS_ACTIONS.INITIALIZE_LAST_BETS: {
            const { lastBets, lastBetsCashedOut } = action;
            return {
                ...state,
                lastBets: mapLastBetsValues(lastBets, lastBetsCashedOut),
            };
        }
        case MY_BETS_ACTIONS.INITIALIZE_MY_BETS: {
            const { myBets } = action;
            return {
                ...state,
                myBets: myBets,
            };
        }
        case TOP_BETS_ACTIONS.INITIALIZE_TOP_ODDS: {
            return {
                ...state,
                top: {
                    ...state.top,
                    odds: action.payload,
                },
            };
        }
        case TOP_BETS_ACTIONS.INITIALIZE_TOP_WINNINGS: {
            return {
                ...state,
                top: {
                    ...state.top,
                    winnings: action.payload,
                },
            };
        }
        case TOP_BETS_ACTIONS.INITIALIZE_TOP_MULTIPLIER_CASH_OUTS: {
            return {
                ...state,
                top: {
                    ...state.top,
                    multiCashOut: action.payload,
                },
            };
        }
        case GAMES_HISTORY_ACTIONS.INITIALIZE_GAMES_HISTORY: {
            return {
                ...state,
                gameHistory: action.history,
            };
        }
        case NEXT_GAMES_ACTIONS.INITIALIZE_NEXT_GAMES: {
            // Temp fix until BE doesn't return current game
            const nextGames = action.games;
            nextGames.shift();

            return {
                ...state,
                nextGames: nextGames,
                currentGame: action.games[0] || undefined,
            };
        }
        case PROFILE_ACTIONS.INITIALIZE_PROFILE: {
            return {
                ...state,
                profile: action.profile,
            };
        }
        case PROFILE_ACTIONS.TOGGLE_CONFIG_VOICE: {
            return {
                ...state,
                configs: {
                    ...state.configs,
                    voiceEffects: action.enable,
                },
            };
        }
        case PROFILE_ACTIONS.TOGGLE_CONFIG_ANIMATION: {
            return {
                ...state,
                configs: {
                    ...state.configs,
                    animate: action.enable,
                },
            };
        }
        case PROFILE_ACTIONS.TOGGLE_CONFIG_BACKGROUND_MUSIC: {
            return {
                ...state,
                configs: {
                    ...state.configs,
                    backgroundMusic: action.enable,
                },
            };
        }
        case ACTIONS.TOGGLE_ENABLE_FREE_BETS: {
            return {
                ...state,
                freeBetsEnabled: action.enabled,
            };
        }
        case ACTIONS.SAVE_GAME_SETTINGS: {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    Player: {
                        ...state.profile.Player,
                        A: action.payload.Avatar,
                    },
                },
            };
        }
        case ACTIONS.LOAD_GAME: {
            return {
                ...state,
                loading: false,
            };
        }
        case ACTIONS.INITIALIZE_GAME_STATE: {
            return {
                ...state,
                status: {
                    ...state.status,
                    multiplier: action.data.Multiplier,
                    state: GAME_STATE_BY_INDEX[action.data.Status],
                    duration: action.data.Duration,
                    durationLeft: action.data.LeftDuration,
                },
            };
        }
        case ACTIONS.INITIALIZE_GAME_SETTINGS: {
            return {
                ...state,
                configs: {
                    ...state.configs,
                    backgroundMusic: action.data.Music,
                    voiceEffects: action.data.Sound,
                },
            };
        }
        case ACTIONS.GAME_ERROR: {
            return {
                ...state,
                errors: {
                    isError: true,
                    type: action.errorType,
                },
            };
        }
        default:
            return state;
    }
};

export default gameReducers;
