import { Dispatch } from 'react';
import { SOCKET_URL } from '../../constants/constants';
import { GAME_METHODS } from '../../constants/enums';
import {
    currentMultiplierAction,
    gameCreatedAction,
    gameCrushedAction,
    gameErrorAction,
    gameStartedAction,
    initializeGameSettingsAction,
    initializeGameStateAction,
    playerBalanceChangeAction,
    playerCashedOutAction,
    playerRegisteredAction,
    playerUnregisteredAction,
} from '../store/actions/app.actions';
import {
    registerFailService,
    registerSuccessService,
    unregisterServiceSuccess,
} from '../store/services';
import {
    initializeCurrentBetsAction,
    initializeLastBetsAction,
} from '../../features/topBoards/bets/configs/store/actions';
import { initializeMyBetsAction } from '../../features/topBoards/myBets/configs/store/actions';
import {
    initializeTopMultiplierCashOutsAction,
    initializeTopOddsAction,
    initializeTopWinningsAction,
} from '../../features/topBoards/topBets/configs/store/actions';
import { initializeGamesHistoryAction } from '../../features/oddsList/configs/store/actions';
import { initializeNextGamesAction } from '../../features/nextGames/configs/store/actions';
import { initializeProfileAction } from '../../features/profile/configs/store/actions';
import {
    initializeChatHistoryAction,
    receiveChatMessageAction,
    receiveSystemChatMessageAction,
    updateChatMessageLikesAction,
} from '../../features/chat/store/actions';
import { getQuery } from '../../helpers/hooks/useQuery';
import { ErrorListNames } from '../../components/Modals/ErrorList';

const setupSocket = (dispatch: Dispatch<any>) => {
    const sessionKey = getQuery(window.location.search, 'sessionKey');
    const socket = new WebSocket(`${SOCKET_URL}?sessionKey=${sessionKey}`);
    let gameOpened = false;

    socket.onmessage = (event: any) => {
        if (event.data.substring(0, 6) !== 'ConnID') {
            if (event.data.includes('SessionKey')) {
                return;
            }
            const dataArray = JSON.parse(event.data);
            for (let data of dataArray) {
                switch (data['Command']) {
                    case GAME_METHODS.GAME_CREATED: {
                        dispatch(gameCreatedAction(data));
                        break;
                    }
                    case GAME_METHODS.GAME_STARTED: {
                        dispatch(gameStartedAction(data));
                        break;
                    }
                    case GAME_METHODS.GAME_CRUSHED: {
                        dispatch(gameCrushedAction(data));
                        break;
                    }
                    case GAME_METHODS.CURRENT_MULTIPLIER: {
                        dispatch(currentMultiplierAction(data));
                        break;
                    }
                    case GAME_METHODS.PLAYER_REGISTERED: {
                        dispatch(playerRegisteredAction(data));
                        break;
                    }
                    case GAME_METHODS.PLAYER_UNREGISTERED: {
                        dispatch(playerUnregisteredAction(data));
                        break;
                    }
                    case GAME_METHODS.PLAYER_CASHED_OUT: {
                        dispatch(playerCashedOutAction(data));
                        break;
                    }
                    case GAME_METHODS.REGISTER_RESPONSE: {
                        if (data['Message'] === 'Success') {
                            registerSuccessService(dispatch, data);
                        } else {
                            registerFailService(dispatch, data);
                        }
                        break;
                    }
                    case GAME_METHODS.UNREGISTER_RESPONSE: {
                        unregisterServiceSuccess(dispatch, data);
                        break;
                    }
                    case GAME_METHODS.PLAYER_BALANCE: {
                        dispatch(playerBalanceChangeAction(data));
                        break;
                    }
                    case GAME_METHODS.CURRENT_STATE: {
                        // Last game players
                        dispatch(
                            initializeLastBetsAction(
                                data['LastGamePlayers'],
                                data['LastGameCashedOutPlayers']
                            )
                        );

                        // Current game players
                        dispatch(
                            initializeCurrentBetsAction(
                                data['CurrentPlayers'],
                                data['CashedOutPlayers']
                            )
                        );

                        // My bets
                        dispatch(initializeMyBetsAction(data['MyBets']));

                        // Top Boards
                        dispatch(
                            initializeTopOddsAction(data['TopMultipliers'])
                        );
                        dispatch(
                            initializeTopMultiplierCashOutsAction(
                                data['TopWonMultipliers']
                            )
                        );
                        dispatch(
                            initializeTopWinningsAction(data['TopCashOuts'])
                        );
                        dispatch(
                            initializeGamesHistoryAction(data['GamesHistory'])
                        );
                        dispatch(initializeNextGamesAction(data['NextGames']));

                        dispatch(
                            initializeGameStateAction({
                                Multiplier: data['CurrentMultiplier'],
                                Status: data['Status'],
                                Duration: data['RegistrationDuration'],
                                LeftDuration: data['LeftRegistrationDuration'],
                            })
                        );

                        dispatch(
                            initializeProfileAction({
                                B: data['B'],
                                MyID: data['MyID'],
                                Player: data['CurrentPlayer'],
                            })
                        );

                        dispatch(
                            initializeChatHistoryAction(data['ChatHistory'])
                        );

                        dispatch(
                            initializeGameSettingsAction({
                                IsChatBlocked: data['IsChatBlocked'],
                                Sound: data['Sound'],
                                Music: data['Music'],
                            })
                        );

                        break;
                    }
                    case GAME_METHODS.MESSAGE: {
                        dispatch(receiveChatMessageAction(data));
                        break;
                    }
                    case GAME_METHODS.MESSAGE_LIKED: {
                        dispatch(updateChatMessageLikesAction(data));
                        break;
                    }
                    case GAME_METHODS.SYSTEM_MESSAGE: {
                        dispatch(receiveSystemChatMessageAction(data));
                        break;
                    }
                    case GAME_METHODS.GAME_DISCONNECT: {
                        dispatch(gameErrorAction(ErrorListNames.Disconnect));
                        gameOpened = true;
                        break;
                    }
                }
            }
        }
    };
    socket.onclose = () => {
        if (!gameOpened) {
            dispatch(gameErrorAction(ErrorListNames.ServerConnection));
        }
    };

    return socket;
};

export default setupSocket;
