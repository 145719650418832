import React, { FC, memo } from 'react';
import styled from 'styled-components';
import ToggleInput from '../../../../components/ToggleInput/ToggleInput';
import NumberInput from '../../../../components/NumberInput/NumberInput';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';

const StyledContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    ${props =>
        props.disabled &&
        `
        opacity: 0.42;
        pointer-events: none;
    `}
`;

const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    flex: 4;
`;

const TextSection = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    flex: 2;
`;

const SwitchSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 2;
`;

const Title = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: ${props => props.theme.colors.whiteGrayish};
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    font-weight: bold;
    font-size: 16px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    @media (max-height: ${750}px), (max-width: ${1100}px) {
        font-size: 13px;
    }
`;

const InputSection = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 55px;
    flex: 5;
`;

const AutoBetPanel: FC<{
    autoBet: boolean;
    enableAutoCashOut: boolean;
    autoCashOut: number;
    setAutoBet(autoBet: boolean): void;
    setEnableAutoCashOut(enableAutoCashOut: boolean): void;
    setAutoCashOut(autoCashOut: number): void;
    panelDisabled: boolean;
    minValue?: number;
    maxValue?: number;
}> = ({
    autoBet,
    setAutoBet,
    enableAutoCashOut,
    setEnableAutoCashOut,
    autoCashOut,
    setAutoCashOut,
    panelDisabled,
    minValue,
    maxValue,
}) => {
    const translate = useTranslate();

    return (
        <StyledContainer disabled={panelDisabled}>
            <ToggleContainer>
                <TextSection>
                    <Title>{translate(KEYWORDS.AutoBetting)}</Title>
                </TextSection>
                <SwitchSection>
                    <ToggleInput
                        active={autoBet}
                        onChange={() => setAutoBet(!autoBet)}
                    />
                </SwitchSection>
            </ToggleContainer>
            <ToggleContainer>
                <TextSection>
                    <Title>{translate(KEYWORDS.AutoCashouting)}</Title>
                </TextSection>
                <SwitchSection>
                    <ToggleInput
                        active={enableAutoCashOut}
                        onChange={() =>
                            setEnableAutoCashOut(!enableAutoCashOut)
                        }
                    />
                </SwitchSection>
            </ToggleContainer>
            <InputSection>
                <NumberInput
                    value={autoCashOut}
                    minValue={minValue}
                    maxValue={maxValue}
                    onChange={setAutoCashOut}
                    disabled={!enableAutoCashOut || panelDisabled}
                    fontSize={21}
                    character={'x'}
                />
            </InputSection>
        </StyledContainer>
    );
};

export default memo(AutoBetPanel);
