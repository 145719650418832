import React, { FC } from 'react';
import Modal from '../Modal';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
    padding: 45px 20px 20px 20px;
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.charcoalGreyTwo} 0 0 no-repeat
        padding-box;
    box-shadow: none;
    width: 600px;
    max-width: 100%;
`;

const Text = styled.div`
    font-family: ${({ theme }) => theme.fonts.mediatorNarrowCaps};
    font-weight: 500;
    line-height: 1.1;
    color: ${({ theme }) => theme.colors.white};
    font-size: 24px;
    text-align: center;
    margin-bottom: 56px;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.weirdGray};
    font-family: ${({ theme }) => theme.fonts.mediatorNarrowCaps};
    border-radius: 25px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.colors.weirdGray};
    cursor: pointer;
`;

export const ErrorModalTemplate: FC<{
    children: React.ReactChildren;
    action: () => void;
    actionText: string;
}> = ({ children, action, actionText }) => {
    return (
        <Modal>
            <Container>
                <Text>{children}</Text>
                <Actions>
                    <Button onClick={action}>{actionText}</Button>
                </Actions>
            </Container>
        </Modal>
    );
};
