import React, { memo, useState } from 'react';
import Table from '../../../components/Table/Table';
import { TableHead } from '../../../components/Table/TableHead';
import { TableHeaderRow } from '../../../components/Table/TableHeaderRow';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell';
import styled from 'styled-components';
import PreviousList from './PreviousList';
import CurrentList from './CurrentList';
import BetsInfoPanel from './components/BetsInfoPanel';
import {
    useCurrentBets,
    usePreviousBets,
} from '../../../config/store/state/app.state';
import useTranslate from '../../../config/language';
import { KEYWORDS } from '../../../config/language/keywords';
import { useProfile } from '../../profile/configs/store/state';
import { ListUser } from '../../../constants/interfaces/List';

const StyledCurrentList = styled.div``;

const StyledScrollableTable = styled.div`
    height: calc(100vh - 200px);
    overflow: auto;
    scrollbar-color: #ffffff21 #373754;
    scrollbar-width: thin;
`;

const BetsList = () => {
    const [showLastBets, setShowLastBets] = useState();
    const translate = useTranslate();
    const profile = useProfile().Player;
    const currentBets = useCurrentBets();
    const previousList = usePreviousBets();

    const yourCurrentBets: ListUser[] = [];
    let sortedCurrentBets = currentBets.sort((a, b) =>
        a.bet > b.bet ? -1 : 1
    );
    sortedCurrentBets = sortedCurrentBets.filter(bet => {
        if (bet.id === profile.I) {
            yourCurrentBets.push(bet);
        }
        return bet.id !== profile.I;
    });
    sortedCurrentBets.unshift(...yourCurrentBets);

    const yourLastBets: ListUser[] = [];
    let sortedPreviousBets = previousList.sort((a, b) =>
        a.bet > b.bet ? -1 : 1
    );
    sortedPreviousBets = sortedPreviousBets.filter(bet => {
        if (bet.id === profile.I) {
            yourLastBets.push(bet);
        }
        return bet.id !== profile.I;
    });
    sortedPreviousBets.unshift(...yourLastBets);

    return (
        <StyledCurrentList>
            <BetsInfoPanel
                showLastBets={showLastBets}
                setShowLastBets={setShowLastBets}
                totalBets={
                    showLastBets ? previousList.length : currentBets.length
                }
            />
            <Table>
                <TableHead>
                    <TableHeaderRow>
                        <TableHeaderCell>
                            {translate(KEYWORDS.Player)}
                        </TableHeaderCell>
                        <TableHeaderCell>
                            {translate(KEYWORDS.BuyIn)}
                        </TableHeaderCell>
                        <TableHeaderCell>
                            {translate(KEYWORDS.Crashed)}
                        </TableHeaderCell>
                        <TableHeaderCell>
                            {translate(KEYWORDS.WinAmount)}
                        </TableHeaderCell>
                    </TableHeaderRow>
                </TableHead>
            </Table>
            <StyledScrollableTable>
                {showLastBets ? (
                    <PreviousList items={sortedPreviousBets} />
                ) : (
                    <CurrentList items={sortedCurrentBets} />
                )}
            </StyledScrollableTable>
        </StyledCurrentList>
    );
};

export default memo(BetsList);
