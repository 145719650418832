import React, { FC } from 'react';
import useTranslate from '../../config/language';
import { ErrorModalTemplate } from './ErrorModalTemplate';
import { ErrorList, ErrorListNames } from './ErrorList';

const ErrorModal: FC<{ type: ErrorListNames }> = ({ type }) => {
    const translate = useTranslate();

    const handleOpenHere = () => {
        window.location.reload();
    };

    return (
        <ErrorModalTemplate
            action={handleOpenHere}
            actionText={translate(ErrorList[type].action)}
        >
            {translate(ErrorList[type].keyword)}
        </ErrorModalTemplate>
    );
};

export default ErrorModal;
