import React, { memo, useRef, useState } from 'react';
import styled from 'styled-components';
import useTranslate from '../../../../config/language';
import { KEYWORDS } from '../../../../config/language/keywords';
import { rgba } from 'polished';
import { useSendMessageService } from '../../store/services';
import ChatEmojis from '../../ChatEmojis/ChatEmojis';
import ChatGifs from '../../ChatGifs/ChatGifs';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { useParseDisplayMessage, useParseSendMessage } from '../../hooks';

const ChatInputContainer = styled.div`
    padding: 16px;
    font-family: ${props => props.theme.fonts.mediatorNarrow};
    height: 127px;
`;

const Textarea = styled(ContentEditable)`
    border: solid 1px ${props => props.theme.colors.charcoalGreyFour};
    border-radius: 4px;
    background: transparent;
    width: 100%;
    font-size: 13px;
    padding: 13px;
    box-sizing: border-box;
    resize: none;
    height: 60px;
    overflow: auto;
    margin-bottom: 8px;
    color: ${props => props.theme.colors.whiteGrayish};

    & > img {
        height: 18px;
        width: 18px;
    }

    &::placeholder {
        color: ${props => rgba(props.theme.colors.white, 0.1)};
    }
    &:focus {
        outline: 0;
    }
`;

const ButtonArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const Button = styled.div`
    width: 117px;
    height: 32px;
    background: ${props => props.theme.colors.vermillion};
    color: ${props => props.theme.colors.white};
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    &:active {
        opacity: 0.7;
    }
`;

const ChatTools = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 0 8px;
`;

const ChatInput = () => {
    const translate = useTranslate();
    const messageService = useSendMessageService();
    const [value, setValue] = useState('');
    const valueRef = useRef<any>(null);

    const parseChatMessage = useParseDisplayMessage();
    const parseSendMessage = useParseSendMessage();

    const handleChange = (e: ContentEditableEvent) => {
        setValue(e.target.value);
    };

    const addBreak = () => {
        setValue(prevState => prevState + '\n');
    };

    const handleMessageSend = () => {
        const value = valueRef?.current?.lastHtml || '';
        if (value.length < 1) return;
        messageService({ Text: parseSendMessage(value) });
        setValue('');
    };

    const [isControlPressed, setIsControlPressed] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Control') {
            setIsControlPressed(true);
        }

        if (event.key === 'Enter' && !isControlPressed) {
            event.stopPropagation();
            event.preventDefault();
            handleMessageSend();
        } else if (event.key === 'Enter' && isControlPressed) {
            addBreak();
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === 'Control') {
            setIsControlPressed(false);
        }
    };

    return (
        <ChatInputContainer>
            <Textarea
                ref={valueRef}
                aria-valuetext={parseChatMessage(value)}
                html={parseChatMessage(value)}
                onChange={handleChange}
                placeholder={translate(KEYWORDS.EnterText)}
                onKeyDown={evt => {
                    handleKeyDown(evt);
                }}
                onKeyUp={evt => {
                    handleKeyUp(evt);
                }}
            />
            <ChatTools>
                <ChatEmojis value={value} setValue={setValue} />
                <ChatGifs value={value} sendGif={messageService} />
                <ButtonArea>
                    <Button onClick={handleMessageSend}>
                        {translate(KEYWORDS.Submit)}
                    </Button>
                </ButtonArea>
            </ChatTools>
        </ChatInputContainer>
    );
};

export default memo(ChatInput);
