import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ActionPanelNavigation from './components/ActionPanelNavigation';
import { ACTION_PANEL_NAVIGATION, ActionPanelNavigationItems } from './tools';
import ErrorMessages from '../errorMessages/ErrorMessages';
import { useErrorMessagesState } from '../errorMessages/store/state';
import AutoBoardContainer from './components/AutoBoard';
import {
    RegisterState,
    useBoards,
    useFreeBetsState,
} from '../../config/store/state/app.state';
import FreeBetBoardContainer from './components/FreeBetBoard';
import ActionBoardContainer from './components/ActionBoard';
import useTranslate from '../../config/language';
import { KEYWORDS } from '../../config/language/keywords';
import { useToggleEnableFreeBetsService } from '../../config/store/services';
import { useMessengerState } from '../chat/store/state';

const StyledActionPanel = styled.div`
    position: relative;
`;

const StyledNavigation = styled.div`
    position: relative;
    border-bottom: 2px solid #ffffff33;
`;

const StyledBody = styled.div<{ isChatOpen: boolean }>`
    display: flex;
    justify-content: center;
    padding: 12px 12px 24px 12px;
    box-sizing: border-box;
    height: 208px;
    @media (max-height: ${550}px) {
        height: 176px;
    }
    @media (max-width: ${({ theme }) => theme.breaks.mobile}px) {
        height: auto;
        flex-direction: column;
    }

    ${({ isChatOpen, theme }) =>
        isChatOpen &&
        `
        @media only screen and (max-width: ${900}px) and (min-width: ${
            theme.breaks.mobile
        }px) {
            height: auto;
            flex-direction: column;
        }
    
        @media only screen and (max-width: ${1220}px) and (min-width: ${1000}px) {
            height: auto;
            flex-direction: column;
        }
    `}
`;

const ReturnToCash = styled.div<{ disabled: boolean }>`
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    text-decoration: underline;
    letter-spacing: 0;
    color: ${({ theme, disabled }) =>
        disabled ? theme.colors.gray : theme.colors.brightSkyBlue};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    font-family: ${({ theme }) => theme.fonts.mediatorNarrow};
    font-weight: bold;
    font-size: 16px;
    margin-top: 8px;
`;

const ActionPanel = () => {
    const freeBetsEnabled = useFreeBetsState();
    const translate = useTranslate();

    const [active, setActive] = useState<ACTION_PANEL_NAVIGATION>(
        ACTION_PANEL_NAVIGATION.MANUAL
    );

    useEffect(() => {
        if (freeBetsEnabled) {
            setActive(ACTION_PANEL_NAVIGATION.MANUAL);
        }
    }, [freeBetsEnabled]);

    const messages = useErrorMessagesState();
    const boards = useBoards();

    const disabled =
        boards.filter(
            board => board.registerState !== RegisterState.UNREGISTERED
        ).length > 0;

    const handleToggleFreeBets = useToggleEnableFreeBetsService();
    const isChatOpen = useMessengerState().open;

    return (
        <StyledActionPanel>
            {messages.length > 0 && <ErrorMessages />}
            <StyledNavigation>
                <ActionPanelNavigation
                    items={ActionPanelNavigationItems}
                    activeIndex={active}
                    setActive={setActive}
                    disabled={disabled || freeBetsEnabled}
                />
            </StyledNavigation>
            {active === ACTION_PANEL_NAVIGATION.MANUAL && freeBetsEnabled && (
                <ReturnToCash
                    disabled={disabled}
                    onClick={() => {
                        !disabled && handleToggleFreeBets(false);
                    }}
                >
                    {translate(KEYWORDS.ReturnToCash)}
                </ReturnToCash>
            )}
            <StyledBody isChatOpen={isChatOpen}>
                {active === ACTION_PANEL_NAVIGATION.MANUAL &&
                    (freeBetsEnabled ? (
                        <FreeBetBoardContainer />
                    ) : (
                        <ActionBoardContainer />
                    ))}
                {active === ACTION_PANEL_NAVIGATION.AUTO && (
                    <AutoBoardContainer />
                )}
            </StyledBody>
        </StyledActionPanel>
    );
};

export default ActionPanel;
