import React, { FC } from 'react';
import SelectDropDown from '../../../../components/SelectDropDown/SelectDropDown';
import NumberInput from '../../../../components/NumberInput/NumberInput';
import styled from 'styled-components';

const OnWinContainer = styled.div<{ disabled?: boolean }>`
    flex: 1;
    display: flex;
    background: #ffffff0a;
    ${props => props.disabled && 'opacity: 0.5; pointer-events: none'}
`;

const StyledNumberInput = styled(NumberInput)`
    background: unset;
`;

const ConditionDropdown: FC<{
    value: number;
    onValueChange(value: number): void;
    selected: 0 | 1;
    selectAction(value: 0 | 1): void;
    items: { text: string; index: number }[];
    disabled?: boolean;
}> = ({ value, onValueChange, selected, selectAction, items, disabled }) => {
    return (
        <OnWinContainer disabled={disabled}>
            <SelectDropDown
                items={items}
                selected={selected}
                selectItem={selectAction}
                disabled={disabled}
            />
            {selected === 1 && (
                <StyledNumberInput
                    value={value}
                    onChange={onValueChange}
                    character={'x'}
                    disabled={disabled}
                />
            )}
        </OnWinContainer>
    );
};

export default ConditionDropdown;
