import React, { FC } from 'react';
import styled from 'styled-components';
import { useGameStatusState } from '../../config/store/state/app.state';
import { GAME_STATE } from '../../constants/interfaces/Game';

const StyledBackground = styled.div<{ flying?: boolean }>`
    background: transparent
        linear-gradient(
            180deg,
            #241e2b 0%,
            #2d0a56 18%,
            #360b5f 44%,
            #2a285f 71%,
            #1e2326 100%
        )
        0 0 no-repeat padding-box;
    position: absolute;
    height: 4800px;
    left: 0;
    bottom: ${props => (props.flying ? '-3400px' : 0)};
    right: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: ${props => (props.flying ? `bottom 50s;` : 'none')};
`;

const BackgroundContainer: FC<{disabled?: boolean}> = ({disabled}) => {
    const running = useGameStatusState().state === GAME_STATE.RUNNING;
    return <StyledBackground flying={!disabled || running} />;
};

export default BackgroundContainer;
