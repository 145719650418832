import { KEYWORDS } from '../../config/language/keywords';

export enum ErrorListNames {
    Disconnect = 'Disconnect',
    ServerConnection = 'ServerConnection',
    ServerError = 'ServerError',
    SessionHasExpired = 'SessionHasExpired',
    TechnicalWork = 'TechnicalWork',
    SitesUpdate = 'SitesUpdate',
}

export const ErrorList = {
    [ErrorListNames.Disconnect]: {
        keyword: KEYWORDS.GameOpenInNewWindow,
        action: KEYWORDS.OpenHere,
    },
    [ErrorListNames.ServerConnection]: {
        keyword: KEYWORDS.ServerConnection,
        action: KEYWORDS.Reload,
    },
    [ErrorListNames.ServerError]: {
        keyword: KEYWORDS.ServerError,
        action: KEYWORDS.Reload,
    },
    [ErrorListNames.SessionHasExpired]: {
        keyword: KEYWORDS.SessionHasExpired,
        action: KEYWORDS.Reload,
    },
    [ErrorListNames.TechnicalWork]: {
        keyword: KEYWORDS.TechnicalWork,
        action: KEYWORDS.Reload,
    },
    [ErrorListNames.TechnicalWork]: {
        keyword: KEYWORDS.TechnicalWork,
        action: KEYWORDS.Reload,
    },
    [ErrorListNames.SitesUpdate]: {
        keyword: KEYWORDS.SitesUpdate,
        action: KEYWORDS.Reload,
    },
};
