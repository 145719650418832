import React, { FC, memo, useState } from 'react';
import styled from 'styled-components';
import UserAvatar from '../../components/UserAvatar';
import ArrowUp from '../../components/Icons/ArrowUp';
import ArrowDown from '../../components/Icons/ArrowDown';
import { useProfile } from './configs/store/state';
import ClickAwayListener from '../../helpers/hooks/clickawayListener';
import ProfileDropdown from './components/ProfileDropdown';

const Container = styled.div`
    display: flex;
    position: relative;
`;

const InfoSection = styled.div`
    margin-left: 8px;
`;

const UserName = styled.div`
    font-size: 13px;
    letter-spacing: 0;
    color: ${(props) => props.theme.colors.whiteGrayish};
    font-family: ${(props) => props.theme.fonts.mediatorNarrow};
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 85px;
`;

const UserBalance = styled.div`
    font-size: 13px;
    letter-spacing: 0;
    color: ${(props) => props.theme.colors.lightOrange};
    font-family: ${(props) => props.theme.fonts.mediatorNarrow};
    font-weight: bold;
`;

const IconHandler = styled.div`
    width: 35px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;
    margin-left: 8px;
    &:active {
        background: ${(props) => props.theme.colors.transparent2};
    }
`;

const IconContainer = styled.div`
    width: 11px;
    height: 100%;
    color: ${(props) => props.theme.colors.white};
`;

const WebProfileSection: FC = () => {
    const [open, setOpen] = useState(false);
    const profile = useProfile();

    return (
        <Container>
            <UserAvatar avatarId={profile?.Player?.A} />
            <InfoSection>
                <UserName>{profile.Player && profile.Player.N}</UserName>
                <UserBalance>
                    {profile.B && profile.B ? profile.B.WalletBalance : 0}₾
                </UserBalance>
            </InfoSection>
            <IconHandler
                onMouseDown={() => {
                    setOpen(!open);
                }}
            >
                <IconContainer>
                    {open ? <ArrowUp /> : <ArrowDown />}
                </IconContainer>
            </IconHandler>
            {open && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <ProfileDropdown
                        profile={profile}
                        handleClose={() => setOpen(false)}
                    />
                </ClickAwayListener>
            )}
        </Container>
    );
};

export default memo(WebProfileSection);
